type Listener<T> = (payload: T, stopPropagation: () => void) => void;

interface ListenerWithPriority<T> {
    listener: Listener<T>;
    priority: number;
}

// Slúži na oznamovanie vlastných udalostí
export class EventEmitter<T> {
    // Pole listenerov s prioritou
    private listeners: ListenerWithPriority<T>[] = [];

    // Pridanie listenera s prioritou (defaultne 0)
    subscribe(listener: Listener<T>, priority: number = 0) {
        this.listeners.push({ listener, priority });
        // Zoradenie listenerov podľa priority (vyššia priorita = skôr sa vykoná)
        this.listeners.sort((a, b) => b.priority - a.priority);
        return () => {
            this.listeners = this.listeners.filter((l) => l.listener !== listener);
        };
    }

    // Emitovanie udalosti
    emit(payload: T) {
        let propagationStopped = false;
        const stopPropagation = () => {
            propagationStopped = true;
        };

        for (const { listener } of this.listeners) {
            if (!propagationStopped) {
                listener(payload, stopPropagation);
            } else {
                break;
            }
        }
    }
}
