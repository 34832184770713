
// Podstránky
import Blank from './views/Blank';
import Branches from './views/settings/branch/Branches';
import Departments from './views/settings/department/Departments';
import Users from './views/settings/user/Users';
import UserRoles from './views/settings/user_role/UserRoles';
import Domains from './views/settings/domain/Domains';
import Languages from './views/settings/language/Languages';
import Countries from './views/settings/country/Countries';
import Currencies from './views/settings/currency/Currencies';
import Watermark from './views/settings/watermark/Watermark';
import EmailAccounts from './views/settings/email_account/EmailAccounts';
import PriceLists from './views/settings/price_list/PriceLists';
import CustomerGroups from './views/settings/customer_group/CustomerGroups';
import Warehouses from './views/settings/warehouse/Warehouses';
import StockAvailabilities from './views/settings/stock_availability/StockAvailabilities';
import Tags from './views/settings/tag/Tags';
import Units from './views/settings/unit/Units';
import DocumentTypes from './views/settings/document_type/DocumentTypes';
import ExternalDocumentTypes from './views/settings/external_document_type/ExternalDocumentTypes';
import VatRates from './views/settings/vat_rate/VatRates';
import NumberSequences from './views/settings/number_sequence/NumberSequences';
import Payments from './views/settings/payment/Payments';
import Shipments from './views/settings/shipment/Shipments';
import Parameters from './views/settings/parameter/Parameters';
import TemplateReports from './views/settings/template_report/TemplateReports';
import TemplateEmails from './views/settings/template_email/TemplateEmails';
import TemplateSms from './views/settings/template_sms/TemplateSms';
import Agreements from './views/settings/agreement/Agreements';
import Automations from './views/settings/automation/Automations';
import CustomTexts from './views/settings/custom_text/CustomTexts';
import CustomFields from './views/settings/custom_field/CustomFields';
import LoyaltySystems from './views/marketing/loyalty_system/LoyaltySystems';
import VolumeDiscounts from './views/marketing/volume_discount/VolumeDiscounts';
import Gifts from './views/marketing/gift/Gifts';
import Vouchers from './views/marketing/voucher/Vouchers';
import Popups from './views/web_content/popup/Popups';
import Products from './views/product/Products';
import Customers from './views/customer/Customers';
import WebStructure from './views/web_structure/WebStructures';
import WebPages from './views/web_page/WebPages';
import Articles from './views/web_content/article/Articles';
import Galleries from './views/web_content/gallery/Galleries';
import Slideshows from './views/web_content/slideshow/Slideshows';
import Forms from './views/web_content/form/Forms';
import Files from './views/file/Files';
import Overviews from './views/overview/Overviews';
import Import from './views/settings/import/Imports';
import ImportQueues from './views/settings/import_queue/ImportQueues';
import Export from './views/settings/export/Exports';
import ExportQueues from './views/settings/export_queue/ExportQueues';
import PriceGroups from './views/settings/price_group/PriceGroups';
import StockMovements from './views/stock_movement/StockMovements';
  
// Pomôcky: 
// https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
// https://www.youtube.com/watch?v=J6jzDfHoj-Y&ab_channel=TheNerdyCanuck

// Zoznam všetkých URL adries
export enum AppRouteUrl {
  // Prehľad
  HOME = '/',
  // Obchod
  ORDERS =                        '/orders/',
  CASHDESK =                      '/cashdesk/',
  DOCUMENTS =                     '/documents/',
  EXTERNAL_DOCUMENTS =            '/external-documents/',
  // Sklad 
  PRODUCTS =                      '/products/',
  STOCK_MOVEMENTS =               '/stock-movements/',
  // Adresár  
  CUSTOMERS =                     '/customers/',
  // Web  
  WEB_STRUCTURE =                 '/web-structure/',
  WEB_PAGES =                     '/web-pages/',
  ARTICLES =                      '/articles/',
  GALLERIES =                     '/galleries/',
  SLIDESHOWS =                    '/slideshows/',
  FORMS =                         '/forms/',
  FILES =                         '/files/',
  POPUPS =                        '/popups/',
  // Marketing - štatistiky
  STATISTICS_ORDERS =             '/statistics-orders/',
  STATISTICS_PROFIT =             '/statistics-profit/',
  STATISTICS_VISITORS =           '/statistics-visitors/',
  STATISTICS_CUSTOMERS =          '/statistics-customers/',
  STATISTICS_PRODUCTS =           '/statistics-products/',
  // Marketing - bonusový systém
  LOYALTY_SYSTEM =                '/loyalty-system/',
  VOLUME_DISCOUNTS =              '/volume-discounts/',
  VOUCHERS =                      '/vouchers/',
  GIFTS =                         '/gifts/',
  // Marketing
  SHOPPING_CARTS =                '/shopping-carts/',
  REVIEWS =                       '/reviews/',
  WATCHDOGS =                     '/watchdogs/',
  // Prepojenie
  POHODA =                        '/pohoda/',
  MONEY =                         '/money/',
  SMS =                           '/sms/',
  // Import / Export
  IMPORT =                        '/import/',
  IMPORT_QUEUE =                  '/import-queue/',
  EXPORT =                        '/export/',
  EXPORT_QUEUE =                  '/export-queue/',
  // Nastavenia
  USERS =                         '/users/',
  USER_ROLES =                    '/user-roles/',
  DOMAINS =                       '/domains/',
  LANGUAGES =                     '/languages/',
  COUNTRIES =                     '/countries/',
  CURRENCIES =                    '/currencies/',
  WATERMARK =                     '/watermark/',
  EMAIL_ACCOUNTS =                '/email-accounts/',
  NOTES =                         '/notes/',
  PRICE_LISTS =                   '/price-lists/',
  PRICE_GROUPS =                  '/price-groups/',
  CUSTOMER_GROUPS =               '/customer-groups/',
  WAREHOUSES =                    '/warehouses/',
  AVAILABILITY =                  '/availability/',
  ORDER_STATUSES =                '/order-statuses/',
  TAGS =                          '/tags/',
  PRODUCT_PARAMETERS =            '/product-parameters/',
  UNITS_OF_MEASURE =              '/units-of-measure/',
  TYPES_OF_DOCUMENTS =            '/types-of-documents/',
  TYPES_OF_EXTERNAL_DOCUMENTS =   '/types-of-external-documents/',
  NUMBER_SEQUENCE =               '/number-sequence/',
  TEMPLATES_OF_PRINTING =         '/templates-of-printing/',
  TEMPLATES_OF_EMAIL =            '/templates-of-email/',
  TEMPLATES_OF_SMS =              '/templates-of-sms/',
  CUSTOMER_AGREEMENT =            '/customer-agreement/',
  PAYMENTS =                      '/payments/',
  SHIPMENTS =                     '/shipments/',
  VAT_RATES =                     '/vat-rates/',
  AUTOMATION =                    '/automation/',
  CUSTOM_TEXTS =                  '/custom-texts/',
  CUSTOM_FIELDS =                 '/custom-fields/',
  BRANCHES =                      '/branches/',
  DEPARTMENTS =                   '/departments/'
};  

// Zoznam všetkých podstránok 
export const AppRoutes: AppRouteType[] = [  
  // Prehľad  
  { path: AppRouteUrl.HOME,                       title: 'Prehľad',                     component: Overviews,               exact: true },  
  // Obchod - odklady sú načítané dynamicky (App.tsx)
  { path: AppRouteUrl.CASHDESK,                   title: 'Pokladňa',                    component: Blank,                  exact: true },  
  // Sklad                                                 
  { path: AppRouteUrl.PRODUCTS,                   title: 'Produkty',                    component: Products,               exact: true },  
  { path: AppRouteUrl.STOCK_MOVEMENTS,            title: 'Pohyby',                      component: StockMovements,         exact: true },  
  // Adresár  
  { path: AppRouteUrl.CUSTOMERS,                  title: 'Adresár',                     component: Customers,              exact: true },  
  // Web                
  { path: AppRouteUrl.WEB_STRUCTURE,              title: 'Navigácia',                   component: WebStructure,           exact: true },  
  { path: AppRouteUrl.WEB_PAGES,                  title: 'Stránky',                     component: WebPages,               exact: true },  
  { path: AppRouteUrl.ARTICLES,                   title: 'Textové články',              component: Articles,               exact: true },  
  { path: AppRouteUrl.GALLERIES,                  title: 'Galérie',                     component: Galleries,              exact: true },  
  { path: AppRouteUrl.SLIDESHOWS,                 title: 'Prezentácie',                 component: Slideshows,             exact: true },  
  { path: AppRouteUrl.FORMS,                      title: 'Formuláre',                   component: Forms,                  exact: true },  
  { path: AppRouteUrl.FILES,                      title: 'Súbory',                      component: Files,                  exact: true },  
  { path: AppRouteUrl.POPUPS,                     title: 'Vyskakovacie okno',           component: Popups,                 exact: true },  
  // Marketing - štatistiky             
  { path: AppRouteUrl.STATISTICS_ORDERS,          title: 'Štatistika objednávok',       component: Blank,                  exact: true },  
  { path: AppRouteUrl.STATISTICS_PROFIT,          title: 'Štatistika obratu a zisku',   component: Blank,                  exact: true },  
  { path: AppRouteUrl.STATISTICS_VISITORS,        title: 'Štatistika návštevnosti',     component: Blank,                  exact: true },  
  { path: AppRouteUrl.STATISTICS_CUSTOMERS,       title: 'Štatistika zákazníkov',       component: Blank,                  exact: true },  
  { path: AppRouteUrl.STATISTICS_PRODUCTS,        title: 'Štatistika produktov',        component: Blank,                  exact: true },  
  // Marketing - bonusový systém              
  { path: AppRouteUrl.LOYALTY_SYSTEM,             title: 'Vernostný program',           component: LoyaltySystems,         exact: true },  
  { path: AppRouteUrl.VOLUME_DISCOUNTS,           title: 'Objemové zľavy',              component: VolumeDiscounts,        exact: true },  
  { path: AppRouteUrl.VOUCHERS,                   title: 'Zľavové kupóny',              component: Vouchers,               exact: true },  
  { path: AppRouteUrl.GIFTS,                      title: 'Darčeky zdarma',              component: Gifts,                  exact: true },  
  // Marketing              
  { path: AppRouteUrl.SHOPPING_CARTS,             title: 'Nákupné košíky',              component: Blank,                  exact: true },  
  { path: AppRouteUrl.REVIEWS,                    title: 'Hodnotenie a recenzie',       component: Blank,                  exact: true },  
  { path: AppRouteUrl.WATCHDOGS,                  title: 'Strážne psy',                 component: Blank,                  exact: true },  
  // Prepojenie             
  { path: AppRouteUrl.POHODA,                     title: 'Pohoda',                      component: Blank,                  exact: true },  
  { path: AppRouteUrl.MONEY,                      title: 'Money',                       component: Blank,                  exact: true },  
  { path: AppRouteUrl.SMS,                        title: 'SMS',                         component: Blank,                  exact: true },  
  // Import / Export              
  { path: AppRouteUrl.IMPORT,                     title: 'Automatický import',          component: Import,                 exact: true },  
  { path: AppRouteUrl.IMPORT_QUEUE,               title: 'Stav importu',                component: ImportQueues,           exact: true },  
  { path: AppRouteUrl.EXPORT,                     title: 'Automatický export',          component: Export,                 exact: true },  
  { path: AppRouteUrl.EXPORT_QUEUE,               title: 'Stav exportu',                component: ExportQueues,           exact: true },  
  // Nastavenia
  { path: AppRouteUrl.USERS,                      title: 'Užívatelia',                  component: Users,                  exact: true },  
  { path: AppRouteUrl.USER_ROLES,                 title: 'Užívateľské role',            component: UserRoles,              exact: true },  
  { path: AppRouteUrl.DOMAINS,                    title: 'Domény',                      component: Domains,                exact: true },  
  { path: AppRouteUrl.LANGUAGES,                  title: 'Jazyky',                      component: Languages,              exact: true },  
  { path: AppRouteUrl.COUNTRIES,                  title: 'Krajiny',                     component: Countries,              exact: true },  
  { path: AppRouteUrl.CURRENCIES,                 title: 'Meny',                        component: Currencies,             exact: true },  
  { path: AppRouteUrl.WATERMARK,                  title: 'Vodoznak',                    component: Watermark,              exact: true },  
  { path: AppRouteUrl.EMAIL_ACCOUNTS,             title: 'Emailové účty',               component: EmailAccounts,          exact: true },  
  { path: AppRouteUrl.PRICE_LISTS,                title: 'Cenníky',                     component: PriceLists,             exact: true },  
  { path: AppRouteUrl.PRICE_GROUPS,               title: 'Cenové skupiny',              component: PriceGroups,             exact: true },  
  { path: AppRouteUrl.CUSTOMER_GROUPS,            title: 'Zákaznícke skupiny',          component: CustomerGroups,         exact: true },  
  { path: AppRouteUrl.WAREHOUSES,                 title: 'Sklady',                      component: Warehouses,             exact: true },  
  { path: AppRouteUrl.AVAILABILITY,               title: 'Dostupnosť',                  component: StockAvailabilities,    exact: true },  
  { path: AppRouteUrl.TAGS,                       title: 'Štítky',                      component: Tags,                   exact: true },  
  { path: AppRouteUrl.PRODUCT_PARAMETERS,         title: 'Parametre produktov',         component: Parameters,             exact: true },  
  { path: AppRouteUrl.UNITS_OF_MEASURE,           title: 'Merné jednotky',              component: Units,                  exact: true },  
  { path: AppRouteUrl.TYPES_OF_DOCUMENTS,         title: 'Druhy a stavy dokladov',      component: DocumentTypes,          exact: true },  
  { path: AppRouteUrl.TYPES_OF_EXTERNAL_DOCUMENTS,title: 'Druhy externých dokladov',    component: ExternalDocumentTypes,  exact: true },  
  { path: AppRouteUrl.NUMBER_SEQUENCE,            title: 'Číslovanie dokladov',         component: NumberSequences,        exact: true },  
  { path: AppRouteUrl.TEMPLATES_OF_PRINTING,      title: 'Šablóny pre tlač',            component: TemplateReports,        exact: true },  
  { path: AppRouteUrl.TEMPLATES_OF_EMAIL,         title: 'Šablóny pre emaily',          component: TemplateEmails,         exact: true },  
  { path: AppRouteUrl.TEMPLATES_OF_SMS,           title: 'Šablóny pre SMS',             component: TemplateSms,            exact: true },  
  { path: AppRouteUrl.CUSTOMER_AGREEMENT,         title: 'Súhlasy partnerov',           component: Agreements,             exact: true },  
  { path: AppRouteUrl.PAYMENTS,                   title: 'Platby',                      component: Payments,               exact: true }, 
  { path: AppRouteUrl.SHIPMENTS,                  title: 'Dopravy',                     component: Shipments,              exact: true },  
  { path: AppRouteUrl.VAT_RATES,                  title: 'Sadzby DPH',                  component: VatRates,               exact: true },  
  { path: AppRouteUrl.AUTOMATION,                 title: 'Automatické služby',          component: Automations,            exact: true },  
  { path: AppRouteUrl.CUSTOM_TEXTS,               title: 'Vlastné texty',               component: CustomTexts,            exact: true },  
  { path: AppRouteUrl.CUSTOM_FIELDS,              title: 'Vlastné polia',               component: CustomFields,           exact: true },  
  { path: AppRouteUrl.BRANCHES,                   title: 'Prevádzky (pobočky)',         component: Branches,               exact: true },  
  { path: AppRouteUrl.DEPARTMENTS,                title: 'Strediská (oddelenia)',       component: Departments,            exact: true },  
];  

// Predpis pre podstránku   
export interface AppRouteType {  
  path: string;  
  title: string;
  exact: boolean;
  component: any;
  props?: any;
};