import React, { useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Modely
import { ContentTag } from '../../../models/Models';

// Utility
import { FindText } from '../../../utility/Search';

// Komponenty
import { Alert, Backdrop, Chip, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import Search from '../../../components/Search';
import MessageBox, { MessageBoxProps } from '../../../components/MessageBox';

// Vstupné parametre
export interface ContentTagsProps {
    ids: number[];
    afterCopy?: () => void;
}

// Komponent pre zoznam užívateľov
const ContentTags = (props?: ContentTagsProps) => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({ open: false, title: '', children: null, onClose: () => {
        setMessageBox(prev => ({ 
            ...prev, open: false
        }));
    } });
    const [search, setSearch] = useState('');
    const [tagTypes, setTagTypes] = useState<string[]>([]);

    // Tabuľka
    const [rows, setRows] = useState<ContentTag[]>([]);
    const [rowsFiltered, setRowsFiltered] = useState<ContentTag[]>([]);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'contenttag', {
                params: {
                    'ids': props?.ids ?? []
                }
            })
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props?.ids]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Typy značiek (automaticky vyplním unikátne typa po zmene zoznamu)
    const loadTagTypes = useCallback(() => {
        var distinct: string[] = [];
        rows.forEach((item) => {
            if(!distinct.includes(item?.type ?? '')) {
                distinct.push(item?.type ?? '');
            }
        });
        setTagTypes(distinct);
    }, [rows]);
    useEffect(() => loadTagTypes(), [loadTagTypes, rows]);

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    // Skopírovanie značky do schránky
    const handleCopy = (tag: string) => {
        try {
            navigator.clipboard.writeText(tag);
            if(props?.afterCopy) {
                props.afterCopy();
            }
        } 
        catch {
            setMessageBox(prev => ({
                ...prev, 
                open: true,
                title: tag,
                children: 'Skopírujte štítok na zvolené miesto.'
            }));
        }     
    };

    return (
        <>
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <MessageBox {...messageBox} />

            <Grid container>
                {/* Vyhľadávanie */}
                <Grid item xs={12} sx={{ mt: { xs: 1, md: 0 } }}>
                    <Search onSearch={s => setSearch(s)}
                        onClear={() => {
                            setSearch('');
                        }}
                        />
                </Grid>

                <Grid item xs={12} mt={3}>
                    <Alert severity="info">
                        Kliknutím na značku ju skopírujete do schránky, následne ju stačí prilepiť (ctrl + v) na požadovné miesto.
                    </Alert>
                </Grid> 

                {/* Zoznam */}
                
                {  tagTypes.map(tagType => (
                    <React.Fragment key={tagType}>
                        
                        <Grid item xs={12} mt={2}>
                            <Typography textAlign="center" variant="h6" gutterBottom>{tagType}</Typography>
                        </Grid>
                        
                        { rowsFiltered.filter(item => item.type === tagType).map(item => (
                            <Grid item mt={1} xs={6} lg={4} key={item.tag}>
                                <Tooltip placement='top' title={item.description ?? ''}>
                                    <Chip label={item.tag} variant="outlined" onClick={() => handleCopy('[' + item.tag + ']')} />
                                </Tooltip>
                            </Grid>
                        )) }

                    </React.Fragment>
                )) }

            </Grid>

        </>
    )
}

export default ContentTags;