import { FormEvent, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { Domain, SelectItem, WebStructure, WebStructureSettings } from '../../models/Models';

// Utility
import { ConvertToInt } from '../../utility/Number';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { FindText } from '../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import { NullMinDate } from '../../utility/Date';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Alert, AlertColor, Avatar, Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, Paper, Select, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import Search from '../../components/Search';
import WebStructureCreate, { WebStructureCreateProps } from './WebStructureCreate';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Komponent pre zoznam 
const WebStructures = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [webStructureCreate, setWebStructureCreate] = useState<WebStructureCreateProps>({
        open: false,
        keepMounted: true,
        webStructures: [],
        onSave: () => loadData(),
        onClose: () => setWebStructureCreate(prev => ({ ...prev, open: false }))
    });
    const [settings, setSettings] = useState<WebStructureSettings>({
        domains: []
    });

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'webstructures' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center', sortable: false },
        { field: 'actived', headerName: 'Aktívne', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        {
            field: 'file', headerName: 'Náhľad', hide: true, width: 80, sortable: false, filterable: false, align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {params.row.other.fileIsImage ? <Avatar alt={params.row.name} src={params.row.other.fileSrcSmall} /> : null}
                </>
            )
        },
        {
            field: 'name', headerName: 'Názov', hide: false, minWidth: 50, flex: 0.8, sortable: false,
            valueGetter: (params: GridValueGetterParams) => (params.row.name),
            renderCell: (params: GridRenderCellParams<number>) => (
                <Box pl={(params.row.other.ordinalNumberLevel ?? 0) * 3}>{params.row.name}</Box>
            )
        },
        { field: 'url', headerName: 'URL stránky', hide: HidePoint().md, minWidth: 80, flex: 0.3, align: 'left', sortable: false, headerAlign: 'center' },
        {
            field: 'activedDateFrom', headerName: 'Aktívne od', hide: true, minWidth: 50, flex: 0.3, sortable: false, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['activedDateFrom']),
            valueFormatter: (params: GridValueFormatterParams) => NullMinDate(params?.value as Date)?.toLocaleDateString() ?? '-'
        },
        {
            field: 'activedDateTo', headerName: 'Aktívne do', hide: true, minWidth: 50, flex: 0.3, sortable: false, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['activedDateTo']),
            valueFormatter: (params: GridValueFormatterParams) => NullMinDate(params?.value as Date)?.toLocaleDateString() ?? '-'
        },
        { field: 'widget', headerName: 'Widget značka', hide: true, minWidth: 50, flex: 0.3 },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: true, minWidth: 50, flex: 0.3, sortable: false, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => (params?.value as Date).toLocaleDateString() ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.3, sortable: false, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        { field: 'ordinalNumber', headerName: 'P.č.', hide: true, minWidth: 50, flex: 0.2, sortable: false, align: 'center', headerAlign: 'center' },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 205, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton disabled={!(params.row.other.ordinalNumberUp ?? false)} aria-label="Nahor" title="Nahor" size="small" onClick={() => handleOrdinalNumber(params.value ?? 0, true)}>
                        <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={!(params.row.other.ordinalNumberDown ?? false)} aria-label="Nadol" title="Nadol" size="small" onClick={() => handleOrdinalNumber(params.value ?? 0, false)}>
                        <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vložiť" title="Vložiť" size="small" onClick={() => handleCreate(0, params.value ?? 0)}>
                        <AddIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<WebStructure[]>([]);
    const [rowsTree, setRowsTree] = useState<SelectItem[]>([]);
    const [rowsSource, setRowsSource] = useState<WebStructure[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<WebStructure[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleCreate = (id: number, parentId?: number, copy?: boolean, field?: string) => {
        setWebStructureCreate(prev => ({
            ...prev,
            id: id,
            parentId: parentId,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.WEB_STRUCTURE);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Posunie poradie záznamu
    const handleOrdinalNumber = (id: number, up: boolean) => {
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'webstructure/ordinalnumber', null, {
                params: {
                    'id': id,
                    'up': up
                }
            })
            .then(response => {
                if (response.data === true) {
                    loadData();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'webstructure/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'webstructure/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Export záznamov
    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options', 'file'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'webstructure')
            .then(response => {
                setRowsSource(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Pregenerovanie zdroja pre zoznam po zmene dát
    useEffect(() => {
        // Vytvorim nový zoznam
        const rowsTemp: WebStructure[] = [];
        // Zoradím pôvodný zoznam
        const rowsSorted = rowsSource.sort((a, b) => (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1);
        // Funkcia pre vloženie vetvy
        const rowsTempAdd = (parentId: number, level: number) => {
            // Odfiltrujem vetvu
            const rowsSortedFilter = rowsSorted.filter(r => r.parentId === parentId);
            // Vložím nájdenú vetvu
            rowsSortedFilter?.forEach((item, index) => {
                rowsTemp.push({
                    ...item,
                    other: {
                        ...item.other,
                        ordinalNumberLevel: level,
                        ordinalNumberUp: (index > 0),
                        ordinalNumberDown: (index < (rowsSortedFilter.length - 1))
                    }
                });
                rowsTempAdd(item.id ?? 0, level + 1);
            });
        };
        rowsTempAdd(0, 0);
        setRows(rowsTemp);
    }, [rowsSource]);

    // Pregenerovanie zdroja pre strom vetiev
    useEffect(() => {
        // Zoznam položiek
        const items: SelectItem[] = [];
        // Zoradím pôvodný zoznam
        const rowsSorted = rowsSource.sort((a, b) => (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1);
        // Funkcia pre rekurzívne vygenerovanie položiek (pomocou level obmedzím rekurzívne vykreslenie kvôli zacykleniu ak bude chybný parent)
        const generate = (parentId: number, parentName: string, level?: number) => {
            if ((level ?? 0) > 30) {
                return [];
            }
            rowsSorted.filter(c => c.parentId === parentId)?.forEach(c => {
                const name = parentName + (parentName.length > 0 ? ' / ' : '') + (c?.name ?? '');
                items.push({ id: c?.id ?? 0, name: name });
                generate(c.id ?? 0, name, (level ?? 0) + 1);
            });
        };
        generate(0, '');

        // Uloženie stavu
        setRowsTree(items);
    }, [rowsSource]);

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    // Načítam nastavenia
    const loadSettings = () => {
        axios
            .get(AppConfig.ApiUri + 'webstructure/settings')
            .then(response => {
                setSettings(response.data);
            });
    };
    useEffect(() => loadSettings(), []);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uložím nastavenia
    const handleSaveSettings = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'webstructure/settings', settings)
            .then(response => {
                if (response.data === true) {
                    setWarning({ ...EmptyWarning, color: 'success', text: 'Nastavenia boli uložené!' });
                }
                else {
                    setWarning({ ...EmptyWarning, color: 'error', text: 'Nastavenia sa nepodarilo uložiť!' });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Spracujem zmeny nastavení
    const handleChangeSettingsDomain = (id: number, webStructureId: number) => {
        setSettings(prev => {
            const next = { ...prev, domains: [...prev?.domains?.filter(item => item.id !== id) ?? [], { id: id, webStructureId: webStructureId }] };
            return next;
        });
    }

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <WebStructureCreate {...webStructureCreate} webStructures={rows} />

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={12} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nové tlačidlo</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>
                        
                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>
                            {/* Nastavenia */}
                            <Grid item xs={12} md={4} lg={3} xl={2}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ p: 2, position: 'absolute', width: '100%', height: '100%' }} component="form" onSubmit={handleSaveSettings}>
                                        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>

                                            {/* Nastavenia */}
                                            <Grid container columnSpacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" gutterBottom component="div" pl={1} pt={1}>Nastavenia</Typography>
                                                    <Typography variant="body2" gutterBottom component="div" pl={1} pb={1}>Hlavná vetva domény</Typography>
                                                </Grid>
                                                {domains.map(domain =>
                                                    <Grid item xs={12} key={domain.id}>
                                                        <FormControl margin="dense" variant="outlined" fullWidth>
                                                            <InputLabel id="webstructure-settings-domain">{domain.name}</InputLabel>
                                                            <Select required labelId="webstructure-settings-domain" label={domain.name} name="parentId"
                                                                value={settings.domains?.find(item => item.id === domain.id)?.webStructureId ?? 0}
                                                                onChange={(e) => { handleChangeSettingsDomain(domain.id ?? 0, ConvertToInt(e.target.value) ?? 0) }}>
                                                                <MenuItem value={0}>Všetky vetvy</MenuItem>
                                                                {rowsTree.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Button disabled={loading} color="secondary" type="submit" variant="outlined" size="medium">Uložiť</Button>
                                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                            {/* Produkty */}
                            <Grid item xs={12} sm lg xl>
                                <Box sx={{ height: '100%', width: '100%' }}>
                                    <DataGrid
                                        getRowId={row => row.id}
                                        density={density}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        columns={columns}
                                        rows={rowsFiltered}
                                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                        loading={loading}

                                        // Dvoj-klik (úprava)
                                        onCellDoubleClick={(e) => {
                                            handleCreate(e.row.id, 0, false, e.field);
                                        }}

                                        // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                        onCellKeyDown={(e, c) => {
                                            if (c.code === 'Enter' && c.ctrlKey) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, 0, true, e.field);
                                                return;
                                            }
                                            if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, 0, false, e.field);
                                                return;
                                            }
                                            if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleDelete(e.row.id, e.row.name);
                                                return;
                                            }
                                        }}

                                        // Filtrácia
                                        filterModel={filterModel}
                                        onFilterModelChange={e => setFilterModel(e)}

                                        // Vybrané záznamy
                                        selectionModel={rowsSelected}
                                        onSelectionModelChange={e => setRowsSelected(e)}

                                        // Stĺpce (automatické ukladanie nastavení)
                                        onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </ContentBottom>
            </Content>

        </>
    )
}

export default WebStructures;