import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { File, FileDirectory, FilesFilter } from '../../models/Models';

// Utility
import { FileOpen } from '../../utility/File';
import Debounce from '../../utility/Debounce';
import { format as dateFormat, format } from 'date-fns';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { useQuery } from '../../utility/URL';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';
import { Settings as DataGridColumnSettings } from '../../utility/DataGrid';

// Komponenty
import { Alert, Avatar, Backdrop, Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Checkbox, CircularProgress, Divider, Grid, IconButton, ListItemIcon, Menu, Paper, Select, Switch, TablePagination, ToggleButton, ToggleButtonGroup, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridRowId, GridSortItem, GridValueFormatterParams, GridValueGetterParams, skSK } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import FilesDirectories from './FilesDirectories';
import FileCreate, { FileCreateProps } from './FileCreate';
import FileMove, { FileMoveProps } from './FileMove';
import FileRename, { FileRenameProps } from './FileRename';
import FileUpload from './FileUpload';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';

// Ikony
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsIcon from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ListIcon from '@mui/icons-material/List';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

interface FilesOptions {
    resize: boolean;
    watermark: boolean;
}

// Rozšírenie pre vlastný index zoradenia
interface GridSortModelIndex extends GridSortItem {
    index: number;
}

// Vstupné parametre
export interface FilesProps {
    directoryId?: number;       // Adresár do ktorého sa má nahrať súbor
    directoryType?: number;     // Type adresáru do ktorého sa má nahrať súbor (systémové označenie napr. produkty)
    watermark?: boolean;        // Zapne alebo vypne (predvolené) automatickú aktiváciu vodoznak na nahraných fotkách
    resize?: boolean;           // Zapne (predvolené) alebo vypne automatické zmenšovanie fotiek
    resizeMaxSize?: number;     // Maximálna veľkosť pre automatické zmenšenie súboru (predvolené podľa nastavenia: AppConfig.FileUpload.ResizeMaxSize)
    onSelectionChanged?: (files: File[], confirm: boolean) => void;
}

// Komponent pre zoznam užívateľov
const Files = (props?: FilesProps) => {

    // Téma
    const theme = useTheme();

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [options, setOptions] = useState<FilesOptions>({
        resize: props?.resize ?? true,
        watermark: props?.watermark ?? false
    });
    const [fileCreate, setFileCreate] = useState<FileCreateProps>({
        open: false,
        keepMounted: true,
        directories: [],
        onDirectoriesChanged: () => loadDataDirectories(),
        onSave: () => setFilesFilter(prev => ({ ...prev })),
        onClose: () => setFileCreate(prev => ({ ...prev, open: false }))
    });
    const [fileMove, setFileMove] = useState<FileMoveProps>({
        open: false,
        keepMounted: true,
        directories: [],
        onSave: () => setFilesFilter(prev => ({ ...prev })),
        onClose: () => setFileMove(prev => ({ ...prev, open: false }))
    });
    const [fileRename, setFileRename] = useState<FileRenameProps>({
        open: false,
        keepMounted: true,
        onSave: () => setFilesFilter(prev => ({ ...prev })),
        onClose: () => setFileRename(prev => ({ ...prev, open: false }))
    });

    // Zmena zobrazenia ľavého panelu
    const [sidebarExtend, setSidebarExtend] = useState<boolean>(LoadLocalSettings('files-sidebar-extend', 'true') === 'true');
    const sidebarExtendSwitch = () => {
        setSidebarExtend(!sidebarExtend);
        SaveLocalSettings('files-sidebar-extend', (!sidebarExtend).toString());
    };

    // Zmena zobrazenia
    const [display, setDisplay] = useState<string>(LoadLocalSettings('files-display', 'normal'));
    const setDisplayAndSave = (value: string) => {
        setDisplay(value);
        SaveLocalSettings('files-display', value, 'normal');
    };
    // Nastavenia stĺpcov
    const gridSettings = new DataGridColumnSettings({ uid: 'files' });
    const filesColumnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'actived', headerName: 'Aktívne', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        {
            field: 'file', headerName: 'Náhľad', hide: HidePoint().sm, width: 80, sortable: false, filterable: false, align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {params.row.fileIsImage ? <Avatar alt={params.row.name} src={params.row.fileSrcSmall} /> : null}
                </>
            )
        },
        { field: 'name', headerName: 'Názov', hide: false, minWidth: 60, flex: 0.4 },
        {
            field: 'size', headerName: 'Veľkosť', hide: false, minWidth: 50, flex: 0.2,
            valueGetter: (params: GridValueGetterParams) => formatFileSize(params.row.size ?? 0)
        },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.3, type: 'date', sortable: false,
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Otvoriť" disabled={(params.row.fileSrc ?? '') === ''} title="Otvoriť" size="small" onClick={() => FileOpen(params.row?.fileSrc, '_blank')}>
                        <SearchIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name ?? '')}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];
    const [filesColumns, setFilesColumns] = useState<GridColDef[]>(filesColumnsDefault);
    const filesColumnsSort: GridSortModelIndex[] = [
        { index: 0, field: 'createdDate', sort: 'desc' },
        { index: 1, field: 'createdDate', sort: 'asc' },
        { index: 2, field: 'name', sort: 'asc' },
        { index: 3, field: 'name', sort: 'desc' },
        { index: 4, field: 'size', sort: 'asc' },
        { index: 5, field: 'size', sort: 'desc' },
    ];
    const [filesFilterModel, setFilesFilterModel] = useState<GridFilterModel>();

    // Súbory
    const [files, setFiles] = useState<File[]>([]);
    const [filesCount, setFilesCount] = useState<number>(0);
    const [filesFilter, setFilesFilter] = useState<FilesFilter>({
        page: 0,
        pageSize: gridSettings.pageSizeApply(30),
        sort: 0,
        search: '',
        directoryId: props?.directoryId ?? parseInt(LoadLocalSettings('files-directory', '0'))
    });
    const [filesSelected, setFilesSelected] = useState<number[]>([]);
    const [filesSelectedMenuEl, setFilesSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [filesFileUploadMenuEl, setFilesFileUploadMenuEl] = useState<null | HTMLElement>(null);

    // Aplikujem uložené nastavenia
    useEffect(() => setFilesColumns(gridSettings.columnApply(filesColumns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Kontextové menu
    const [filesMenuItem, setFilesMenuItem] = useState<File>();
    const [filesMenuEl, setFilesMenuEl] = useState<null | HTMLElement>(null);

    // Funkcia pre otvorenie kontexového menu zložky
    const handleFilesMenuOpen = (e: HTMLElement, file: File) => {
        setFilesMenuItem(file);
        setFilesMenuEl(e);
    };

    // Zmena označených súborov
    const handleFileOnChecked = (id: number) => {
        if (filesSelected.includes(id)) {
            setFilesSelected([...filesSelected.filter(f => f !== id)]);
        } else {
            setFilesSelected([...filesSelected, id]);
        }
    };

    // Ak sa zmení zoznam, tak automaticky zruším výber
    useEffect(() => {
        if (filesSelected.length > 0) {
            setFilesSelected([]);
        }
    }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatické odovzdanie označených súborov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            props.onSelectionChanged(files.filter(file => filesSelected.includes(file.id ?? 0)), false);
        }
    }, [filesSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Po kliknutí odovzdám alebo označím súbor
    const handleFileClick = (file: File) => {
        // Ak požadujem výber súboru, tak po kliknutí prenesiem konkrétny súbor
        if (props?.onSelectionChanged) {
            props.onSelectionChanged([file], true);
            return;
        }
        // Predvolená akcia bude zmena označenia
        handleFileOnChecked(file.id ?? 0);
    };

    // Po dvoj-kliku otvorím súbor
    const handleFileDoubleClick = (file: File) => {
        if (file.fileSrc !== undefined) {
            FileOpen(file.fileSrc, '_blank');
        }
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number) => {
        setFileCreate(prev => ({
            ...prev,
            id: id,
            open: true
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.FILES);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Presunúť záznamy
    const handleMoveList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setFileMove(prev => ({
            ...prev,
            ids: ids,
            open: true
        }));
    };

    // Premenovať záznamy
    const handleRenameList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setFileRename(prev => ({
            ...prev,
            ids: ids,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento súbor?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'file/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať súbory: ' + ids.length, children: 'Skutočne chcete vymazať vybrané súbory?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'file/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Obnoviť záznam
    const handleRestore = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete obnoviť tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'file/restore/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleRestoreList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Obnoviť súbory: ' + ids.length, children: 'Skutočne chcete obnoviť vybrané súbory?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'file/restore/list', null, {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setFiles(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Priečinky
    const [directories, setDirectories] = useState<FileDirectory[]>([]);

    // Funkcia pre načítanie zložiek
    const loadDataDirectories = (initialize?: boolean) => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'filedirectory')
            .then(response => {
                const list = response.data as FileDirectory[];
                setDirectories(list);
                // Pri prvom načítaní zoznamu adresárov predvolím adresár podľa vybraného typu (neplatí ak už je vybraný konkrétny adresár)
                if ((props?.directoryType ?? 0) > 0 && (filesFilter.directoryId ?? 0) === 0 && initialize && list.length > 0) {
                    var directoryId = list.find(item => item.type === props?.directoryType)?.id ?? 0;
                    if (directoryId > 0) {
                        setFilesFilter(prev => ({ ...prev, page: 0, directoryId: directoryId }));
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDataDirectories(true), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia po kliknutí na zložku
    const handleDirectoriesClick = (id: number) => {
        if (filesFilter.directoryId !== id) {
            setFilesFilter(prev => ({ ...prev, page: 0, directoryId: id }));
            SaveLocalSettings('files-directory', id.toString());
        }
    };

    // Funkcia pre naformátovanie veľkosti súboru (vstup kB)
    const formatFileSize = (size: number): string => {
        if (size > 1024000) {
            return (size / (1024 * 1024)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' GB';
        } else if (size > 1024) {
            return (size / 1024).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' MB';
        } else {
            return size.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' kB';
        }
    }

    // Funkcia pre načítanie súborov
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'file', {
                params: filesFilter
            })
            .then(response => {
                setFiles(response.data?.list ?? []);
                setFilesCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => setFilesFilter(prev => ({ ...prev }));

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [filesFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <FileCreate {...fileCreate} directories={directories} />

            {/* Formulár pre presun záznamov */}
            <FileMove {...fileMove} directories={directories} />

            {/* Formulár pre premenovanie záznamov */}
            <FileRename {...fileRename} />

            {/* Kontextové menu položky */}
            <Menu id="menu-directories" anchorEl={filesMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesMenuEl)} onClose={() => setFilesMenuEl(null)}>
                <MenuItem dense onClick={() => { if (filesMenuItem !== undefined && filesMenuItem?.fileSrc !== undefined) { FileOpen(filesMenuItem?.fileSrc, '_blank'); } setFilesMenuEl(null); }}>
                    <ListItemIcon><SearchIcon fontSize="small" /></ListItemIcon> Otvoriť súbor
                </MenuItem>
                <Divider />
                {filesFilter.directoryId === -2 && (
                    <MenuItem dense onClick={() => { handleRestore(filesMenuItem?.id ?? 0, filesMenuItem?.name ?? ''); setFilesMenuEl(null); }}>
                        <ListItemIcon><RestoreIcon fontSize="small" /></ListItemIcon> Obnoviť súbor
                    </MenuItem>
                )}
                {filesFilter.directoryId !== -2 && ([
                    <MenuItem dense key={0} onClick={() => { handleCreate(filesMenuItem?.id ?? 0); setFilesMenuEl(null); }}>
                        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť súbor
                    </MenuItem>
                    ,
                    <MenuItem dense key={1} onClick={() => { handleDelete(filesMenuItem?.id ?? 0, filesMenuItem?.name ?? ''); setFilesMenuEl(null); }}>
                        <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať súbor
                    </MenuItem>
                ])}
            </Menu>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md>

                            {/* Nahrať súbor */}
                            <FileUpload onUploaded={(ids) => { loadDataSafe(); }} directoryId={filesFilter.directoryId > 0 ? filesFilter.directoryId : 0} watermark={options.watermark} resize={options.resize} resizeMaxSize={props?.resizeMaxSize} />

                            {/* Nahrať súbor (možnosti) */}
                            <IconButton color="primary" aria-label="Nastavenia nahrávania súborov" aria-controls="menu-fileupload-settings" aria-haspopup="true" onClick={(e) => setFilesFileUploadMenuEl(e.currentTarget)}>
                                <SettingsIcon />
                            </IconButton>
                            <Menu id="menu-fileupload-settings" anchorEl={filesFileUploadMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '45px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesFileUploadMenuEl)} onClose={() => setFilesFileUploadMenuEl(null)}>
                                <MenuItem onClick={() => { setOptions(prev => ({ ...prev, watermark: !prev.watermark })) }}>
                                    <ListItemIcon><Switch edge="start" checked={options.watermark} size="small" /></ListItemIcon> Použiť vodoznak
                                </MenuItem>
                                <MenuItem onClick={() => { setOptions(prev => ({ ...prev, resize: !prev.resize })) }}>
                                    <ListItemIcon><Switch edge="start" checked={options.resize} size="small" /></ListItemIcon> Zmenšiť nahrávané fotky ({AppConfig.FileUpload.ResizeMaxSize}px)
                                </MenuItem>
                            </Menu>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={filesSelected?.length === 0} aria-label="Vybrané súbory" aria-controls="menu-selected-files" aria-haspopup="true" onClick={(e) => setFilesSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + filesSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected-files" anchorEl={filesSelectedMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '45px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(filesSelectedMenuEl)} onClose={() => setFilesSelectedMenuEl(null)} >

                                {filesFilter.directoryId === -2 && (
                                    <MenuItem onClick={() => { handleRestoreList(filesSelected); setFilesSelectedMenuEl(null); }}>
                                        <ListItemIcon><RestoreIcon fontSize="small" /></ListItemIcon> Obnoviť súbory
                                    </MenuItem>
                                )}
                                {filesFilter.directoryId !== -2 && ([
                                    <MenuItem key={0} onClick={() => { handleMoveList(filesSelected); setFilesSelectedMenuEl(null); }}>
                                        <ListItemIcon><OpenWithIcon fontSize="small" /></ListItemIcon> Presunúť súbory
                                    </MenuItem>
                                    ,
                                    <MenuItem key={1} onClick={() => { handleRenameList(filesSelected); setFilesSelectedMenuEl(null); }}>
                                        <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Premenovať súbory
                                    </MenuItem>
                                    ,
                                    <Divider key={2} />
                                    ,
                                    <MenuItem key={3} onClick={() => { handleDeleteList(filesSelected); setFilesSelectedMenuEl(null); }}>
                                        <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať súbory
                                    </MenuItem>
                                ])}

                                <Divider />
                                <MenuItem onClick={() => { setFilesSelected([...files.map(file => file.id ?? 0)]); }}>
                                    <ListItemIcon><CheckBoxOutlinedIcon fontSize="small" /></ListItemIcon> Vybrať všetko
                                </MenuItem>
                                <MenuItem onClick={() => { setFilesSelected([]); setFilesSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <ToggleButtonGroup exclusive value={display} size="small" aria-label="Veľkosť zobrazenia" sx={{ mr: { xs: 0, md: 1 }, backgroundColor: theme.layout.content.backgroundColor }} onChange={(e: any, d) => { if (d !== null) { setDisplayAndSave(d); } }}>
                                <ToggleButton value="normal" aria-label="Normálne zobrazenie">
                                    <Tooltip title="Normálne zobrazenie">
                                        <GridOnIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="detailed" aria-label="Podrobné zobrazenie">
                                    <Tooltip title="Podrobné zobrazenie">
                                        <GridViewIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="list-compact" aria-label="Riadkové kompaktné zobrazenie">
                                    <Tooltip title="Riadkové kompaktné zobrazenie">
                                        <ListIcon />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="list-normal" aria-label="Riadkové normálne zobrazenie">
                                    <Tooltip title="Riadkové normálne zobrazenie">
                                        <FormatListBulletedIcon />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setFilesFilter(prev => ({ ...prev, page: 0, search: s }))} onClear={() => setFilesFilter(prev => ({ ...prev, page: 0, search: '' }))} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>
                            {/* Adresáre */}
                            <Grid item xs={12} md={(sidebarExtend ? 5 : 4)} lg={(sidebarExtend ? 4 : 3)} xl={(sidebarExtend ? 3 : 2)}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ position: 'absolute', width: '100%', height: '100%' }}>
                                        <Box sx={{ position: 'relative', width: '100%', height: '100%', overflowY: 'auto', p: 1 }}>
                                            <FilesDirectories directories={directories} hideTrash={false} activeDirectoryId={filesFilter.directoryId} onChanged={loadDataDirectories} onClick={handleDirectoriesClick} />
                                            <IconButton size="small" sx={{ position: 'absolute', top: 70, right: 15, opacity: 0.5, '&:hover': { opacity: 1 } }} onClick={() => sidebarExtendSwitch()}>
                                                {sidebarExtend ? <CloseFullscreenIcon fontSize='small' /> : <OpenInFullIcon fontSize='small' />}
                                            </IconButton>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                            {/* Súbory */}
                            <Grid item xs={12} sm lg xl>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>

                                    <div style={{ display: ((display === 'list-normal' || display === 'list-compact') ? 'flex' : 'none'), height: '100%' }}>
                                        <DataGrid
                                            getRowId={row => row.id}
                                            density={(display === 'list-normal' ? 'standard' : 'compact')}
                                            checkboxSelection
                                            disableSelectionOnClick
                                            columns={filesColumns}
                                            rows={files}
                                            rowCount={filesCount}

                                            pagination
                                            paginationMode="server"
                                            page={filesFilter.page}
                                            pageSize={filesFilter.pageSize}

                                            rowsPerPageOptions={[10, 20, 30, 50, 100]}

                                            onPageChange={(page) => setFilesFilter(prev => ({ ...prev, page: page }))}
                                            onPageSizeChange={(pageSize) => {
                                                setFilesFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                                gridSettings.pageSizeChanged(pageSize);
                                            }}

                                            sortingMode="server"
                                            sortModel={[filesColumnsSort.find(item => item.index === filesFilter.sort) ?? filesColumnsSort[0]]}
                                            onSortModelChange={(model) => {
                                                if (model.length > 0) {
                                                    setFilesFilter(prev => ({ ...prev, sort: filesColumnsSort.find(item => item.field === model[0].field && item.sort === model[0].sort)?.index ?? 0 }));
                                                } else {
                                                    setFilesFilter(prev => ({ ...prev, sort: 0 }));
                                                }
                                            }}

                                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                            loading={loading}

                                            // Dvoj-klik (úprava)
                                            onCellDoubleClick={(e) => {
                                                if (props?.onSelectionChanged) {
                                                    handleFileClick(e.row as File);
                                                }
                                                else {
                                                    handleCreate(e.row.id);
                                                }
                                            }}

                                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                            onCellKeyDown={(e, c) => {
                                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                    c.preventDefault();
                                                    c.stopPropagation();
                                                    handleCreate(e.row.id);
                                                    return;
                                                }
                                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                    c.preventDefault();
                                                    c.stopPropagation();
                                                    handleDelete(e.row.id, e.row.name);
                                                    return;
                                                }
                                            }}

                                            // Filtrácia
                                            filterModel={filesFilterModel}
                                            onFilterModelChange={e => setFilesFilterModel(e)}

                                            // Vybrané záznamy
                                            selectionModel={filesSelected.map(id => id ?? 0)}
                                            onSelectionModelChange={(e: GridRowId[]) => setFilesSelected(e.map(id => id as number))}

                                            // Stĺpce (automatické ukladanie nastavení)
                                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, filesColumnsDefault)}
                                        />
                                    </div>

                                    <div style={{ display: ((display === 'normal' || display === 'detailed') ? 'flex' : 'none'), height: '100%' }}>
                                        <Paper variant="outlined" sx={{ p: 1, position: 'absolute', width: '100%', height: '100%', paddingBottom: '50px' }}>
                                            <Grid container columnSpacing={2} sx={{ position: 'absolute', bottom: '0px', left: '0px', zIndex: 10 }}>
                                                <Grid item xs={12} lg={3} xl={2}>
                                                    <Select fullWidth sx={{ mt: 1.5, ml: 2 }} required value={filesFilter.sort} size="small" variant="standard" onChange={(e) => setFilesFilter(prev => ({ ...prev, sort: parseInt(e.target.value.toString()) }))}>
                                                        <MenuItem value={0}>Zoradiť od najnovšieho</MenuItem>
                                                        <MenuItem value={1}>Zoradiť od najstaršieho</MenuItem>
                                                        <MenuItem value={2}>Zoradiť abecedne A-Z</MenuItem>
                                                        <MenuItem value={3}>Zoradiť abecedne Z-A</MenuItem>
                                                        <MenuItem value={4}>Zoradiť od najmenšieho</MenuItem>
                                                        <MenuItem value={5}>Zoradiť od najväčšieho</MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} lg xl>
                                                    <TablePagination
                                                        component="div"
                                                        count={filesCount}
                                                        page={filesFilter.page}
                                                        onPageChange={(e, p) => setFilesFilter(prev => ({ ...prev, page: p }))}
                                                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                                                        rowsPerPage={filesFilter.pageSize}
                                                        labelRowsPerPage={HidePoint().sm ? 'Počet:' : 'Počet na stranu:'}
                                                        onRowsPerPageChange={(e) => {
                                                            setFilesFilter(prev => ({ ...prev, page: 0, pageSize: parseInt(e.target.value) }));
                                                            gridSettings.pageSizeChanged(parseInt(e.target.value));
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Box sx={{ width: '100%', height: '100%', overflowY: 'auto', pb: 1 }}>
                                                {/* Zoznamy (prázdny) */}
                                                <Grid item xs={12} md lg xl  {...((files.length > 0 || loading) && { display: "none" })}>
                                                    <Box alignItems="center" sx={{ display: 'flex', minHeight: '300px', textAlign: 'center', justifyContent: 'center' }}>
                                                        <SearchOffIcon sx={{ mr: 2, color: 'silver', fontSize: '50px', display: 'block' }} />
                                                        <Typography display="block">Žiadne záznamy</Typography>
                                                    </Box>
                                                </Grid>

                                                {/* Zoznam */}
                                                <Grid container columnSpacing={1} rowSpacing={1} {...(files.length === 0 && { display: "none" })}>
                                                    {filesFilter.directoryId === -2 && (
                                                        <Grid item xs={12}>
                                                            <Alert severity="warning">Súbory v koši budú 30 dní po vymazaní automaticky odstránené z disku, následne ich už nebude možné obnoviť.</Alert>
                                                        </Grid>
                                                    )}
                                                    {files.map((file) => (
                                                        <Grid key={file.id} item xs={6} sm={3} md={4} lg={3} xl={2}>
                                                            <Card onContextMenu={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleFilesMenuOpen(e.currentTarget, file);
                                                            }}>
                                                                <CardActionArea onClick={() => handleFileClick(file)} onDoubleClick={() => handleFileDoubleClick(file)}>
                                                                    {file.fileIsImage ? (
                                                                        <CardMedia component="img" image={(file.fileSrcMedium ?? '') + '?t=' + (new Date(file.updatedDate ?? '')).getTime()} alt={file.name ?? ''} sx={{ height: { xs: '100px', md: '150px' } }} />
                                                                    ) : (
                                                                        <CardMedia component="div" sx={{ display: 'flex', height: { xs: '100px', md: '150px' }, justifyContent: 'center', alignItems: 'center' }}>
                                                                            <InsertDriveFileOutlinedIcon fontSize="large" />
                                                                        </CardMedia>
                                                                    )}
                                                                    {display === 'detailed' && (
                                                                        <CardContent>
                                                                            <Typography fontSize="small" fontWeight={600} noWrap>{file.originalName}</Typography>
                                                                            <Typography fontSize="small" noWrap>Veľkosť: {formatFileSize(file.size ?? 0)}</Typography>
                                                                            <Typography fontSize="small" noWrap>Vytvorený: {dateFormat(new Date(file.createdDate ?? ''), 'dd.MM.yyyy HH:mm')}</Typography>
                                                                            <Typography fontSize="small" noWrap>Upravený: {new Date(file.updatedDate ?? '').getFullYear() > 2000 ? dateFormat(new Date(file.updatedDate ?? ''), 'dd.MM.yyyy HH:mm') : '-'}</Typography>
                                                                            {filesFilter.directoryId === -2 && <Typography fontSize="small" noWrap>Vymazaný: {dateFormat(new Date(file.deletedDate ?? ''), 'dd.MM.yyyy HH:mm')}</Typography>}
                                                                        </CardContent>
                                                                    )}
                                                                </CardActionArea>
                                                                <CardActions>
                                                                    <Checkbox checked={filesSelected.includes(file.id ?? 0)}
                                                                        onChange={(e) => {
                                                                            e.stopPropagation();
                                                                            handleFileOnChecked(file.id ?? 0);
                                                                        }} />
                                                                    <Typography noWrap fontSize="small">
                                                                        {file.name}
                                                                    </Typography>
                                                                    <IconButton sx={{ marginLeft: 'auto' }} onClick={(e) => handleFilesMenuOpen(e.currentTarget, file)}>
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </div>

                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </ContentBottom>
            </Content>

        </>
    )
}

export default Files;