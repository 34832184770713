import { useEffect, useMemo, useState } from 'react';
import { AppRouteUrl } from '../../AppRoutes';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { StockMovementsFilter, StockMovementLite, DocumentType, Department, Warehouse } from '../../models/Models';

// Utility
import Debounce from '../../utility/Debounce';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { FormatDecimal } from '../../utility/Format';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';
import format from 'date-fns/format';

// Komponenty
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, IconButton, Link, ListItemIcon, ListSubheader, Menu, Select } from '@mui/material';
import { DataGrid, GridColDef, GridDensity, GridFilterModel, GridRenderCellParams, GridRowId, GridSortItem, GridSortModel, GridValueFormatterParams, GridValueGetterParams, skSK } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import DocumentCreate, { DocumentCreateProps } from '../document/DocumentCreate';
import StockMovementsFiltering, { StockMovementsFilteringProps } from './StockMovementsFiltering';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import ReportDocument, { ReportDocumentProps } from '../report/ReportDocument';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ConvertToInt } from '../../utility/Number';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';

// Vstupné parametre
export interface StockMovementsProps {
    filter?: StockMovementsFilter;
    filterDisableAutosave?: boolean; // vypnutie autosave pri zobrazení cez dialog
    onSelectionChanged?: (rows: StockMovementLite[], confirm: boolean) => void;
}

// Komponent pre zoznam
const StockMovements = (props?: StockMovementsProps) => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [stockMovementCreate, setDocumentCreate] = useState<DocumentCreateProps>({
        documentType: {},
        documentTypes: [],
        open: false,
        keepMounted: true,
        disableSaveAction: true,
        onSave: (id?: number) => setRowsFilter(prev => ({ ...prev })),
        onClose: () => setDocumentCreate(prev => ({ ...prev, open: false }))
    });
    const [stockMovementsFiltering, setRowsFiltering] = useState<StockMovementsFilteringProps>({
        open: false,
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setRowsFiltering(prev => ({ ...prev, open: false }))
    });

    // Tlač dokladu
    const [documentPrint, setDocumentPrint] = useState<ReportDocumentProps>({
        documentId: 0,
        documentTypeId: 0,
        open: false,
        preview: true,
        disableHtml: true,
        keepMounted: true,
        onClose: () => setDocumentPrint(prev => ({ ...prev, open: false }))
    });

    // Načítam zoznam 
    const loadDocumentTypes = () => {
        axios
            .get(AppConfig.ApiUri + 'documenttype')
            .then(response => setDocumentTypes(response.data as DocumentType[]));
    };
    useEffect(() => loadDocumentTypes(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const gridSettings = new DataGridColumnSettings({ uid: 'stockmovements' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'inbound', headerName: 'Pohyb', hide: false, width: 50, type: 'boolean', renderCell: (params: GridRenderCellParams<boolean>) => (params.value ?? false) === true ? <AddCircleIcon fontSize='small' /> : <RemoveCircleIcon fontSize='small' /> },
        { field: 'stockMovementTypeName', headerName: 'Druh pohybu', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'departmentName', headerName: 'Stredisko (oddelenie)', hide: true, minWidth: 60, flex: 0.3 },
        { field: 'branchName', headerName: 'Prevádzka (pobočka)', hide: true, minWidth: 60, flex: 0.3 },
        { field: 'warehouseName', headerName: 'Sklad', hide: false, minWidth: 60, flex: 0.3 },
        { field: 'number', headerName: 'Doklad', hide: HidePoint().lg, minWidth: 60, flex: 0.2, renderCell: (params: GridRenderCellParams<number>) => <Link href={AppRouteUrl.DOCUMENTS + (params.row.documentTypeId ?? 0).toString() + '/?id=' + (params.row.documentId ?? 0).toString()} target="_blank">{params.value}</Link> },
        { field: 'regId', headerName: 'IČO', hide: true, minWidth: 60, flex: 0.2 },
        { field: 'code', headerName: 'Kód', hide: false, minWidth: 60, flex: 0.2, renderCell: (params: GridRenderCellParams<number>) => <Link href={AppRouteUrl.PRODUCTS + '?id=' + (params.row.productId ?? 0).toString()} target="_blank">{params.value}</Link> },
        { field: 'codeSupplier', headerName: 'Kód dodávateľa', hide: true, minWidth: 60, flex: 0.2 },
        { field: 'mpn', headerName: 'Kód výrobcu (MPN)', hide: true, minWidth: 60, flex: 0.2 },
        { field: 'ean', headerName: 'EAN', hide: true, minWidth: 60, flex: 0.2 },
        { field: 'name', headerName: 'Názov', hide: false, minWidth: 60, flex: 0.4 },
        {
            field: 'date', headerName: 'Dátum', hide: false, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['date']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        { field: 'quantity', headerName: 'Množstvo', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2) },
        { field: 'unit', headerName: 'MJ', hide: true, minWidth: 60, flex: 0.2 },
        { field: 'price', headerName: 'Čiastka', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2) },
        { field: 'priceTotal', headerName: 'Celkom', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2) },
        { field: 'valuation', headerName: 'Ocenenie', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2) },
        { field: 'stockPriceWac', headerName: 'VNC', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2) },
        {
            field: 'profit', headerName: 'Zisk', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2),
            cellClassName: (params) => ((params.value ?? 0) < 0) ? 'gridstyle-negative' : ''
        },
        {
            field: 'stockQuantity', headerName: 'Stav zásob', hide: false, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2),
            cellClassName: (params) => ((params.value ?? 0) < 0) ? 'gridstyle-negative' : ''
        },
        { field: 'stockValuation', headerName: 'Hodnota zásob', hide: true, minWidth: 70, flex: 0.2, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2) },
        {
            field: 'expirationDate', headerName: 'Záruka do', hide: true, minWidth: 50, flex: 0.3, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['date']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        { field: 'done', headerName: 'Vybavené', hide: true, width: 70, type: 'boolean' },
        { field: 'customerName', headerName: 'Partner', hide: HidePoint().lg, minWidth: 60, flex: 0.3, renderCell: (params: GridRenderCellParams<number>) => (params.row.customerId ?? 0) > 0 ? <Link href={AppRouteUrl.CUSTOMERS + '?id=' + (params.row.customerId ?? 0).toString()} target="_blank">{params.value}</Link> : params.value },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 100, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Vytlačiť" title="Vytlačiť" size="small" onClick={() => handlePrint(params.row.documentId ?? 0, params.row.documentTypeId ?? 0)}>
                        <PrintIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => setHandleCreateHook({ id: params.row.documentId ?? 0, typeId: params.row.documentTypeId ?? 0 })}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<StockMovementLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const rowsFilterSaved = useMemo<StockMovementsFilter>(() => {
        let savedJSON = LoadLocalSettings('stockmovements-filter');
        if (savedJSON.length > 0) {
            try {
                return JSON.parse(savedJSON) as StockMovementsFilter;
            }
            catch (error) { }
        }
        return {}
    }, []);
    const [rowsFilter, setRowsFilter] = useState<StockMovementsFilter>({
        departmentId: rowsFilterSaved.departmentId,
        warehouseId: rowsFilterSaved.warehouseId,
        ...props?.filter,
        page: 0,
        pageSize: gridSettings.pageSizeApply(25)
    });
    const [rowsFilterAutosave, setRowsFilterAutosave] = useState<boolean>(false);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Automatické uloženie naposledy použitého filtru, aktivované až po prvom zobrazí zoznamu (môže byť vypnuté ak je zoznam zobrazený cez popup)
    useEffect(() => {
        try {
            if (rowsFilterAutosave === true && (props?.filterDisableAutosave ?? false) === false) {
                SaveLocalSettings('stockmovements-filter', JSON.stringify(rowsFilter));
            }
        }
        catch (error) { }
    }, [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.stockMovementTypeId ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockMovementType ?? 0) !== 0) { count++; }
        if ((rowsFilter.customerId ?? 0) !== 0) { count++; }
        if (rowsFilter.dateFrom !== undefined) { count++; }
        if (rowsFilter.dateTo !== undefined) { count++; }
        if (rowsFilter.expirationDateFrom !== undefined) { count++; }
        if (rowsFilter.expirationDateTo !== undefined) { count++; }
        if ((rowsFilter.quantityFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.quantityTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockQuantityFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockQuantityTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.priceFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.priceTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockPriceWacFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockPriceWacTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.number ?? '') !== '') { count++; }
        if ((rowsFilter.customer ?? '') !== '') { count++; }
        return count;
    };

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            const ids = rowsSelected.map(r => r as number);
            props.onSelectionChanged(rows.filter(item => ids.includes((item.id ?? 0))) ?? [], false);
        }
    }, [rowsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        // Predvolené
        let sort: string = 'date';
        let sortAsc: boolean = false;
        // Vybrané
        if (sortModel !== undefined && sortModel.length > 0) {
            sort = sortModel[0].field.toLowerCase();
            sortAsc = (sortModel[0].sort === 'asc');
        }
        if (rowsFilter.sort !== sort || rowsFilter.sortAsc !== sortAsc) {
            setRowsFilter(prev => ({ ...prev, sort: sort, sortAsc: sortAsc }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Dvoj-klik v zozname
    const handleDoubleClick = (id: number, typeId: number) => {
        // Ak požadujem výber záznamu, tak po dvoj-kliku prenesiem konkrétny záznam
        if (props?.onSelectionChanged) {
            const item = rows.find(item => item.id === id);
            if (item !== undefined) {
                props.onSelectionChanged([item], true);
                return;
            }
        }
        // Predvolená akcia pre úpravu záznamu
        setHandleCreateHook({
            id: id,
            typeId: typeId
        });
    };

    // Vygenerovať nový alebo stiahnuť nahraný doklad
    const handlePrint = (id: number, typeId: number) => {
        if (id > 0) {
            setDocumentPrint(prev => ({ ...prev, documentId: id, documentTypeId: typeId, open: true }));
            return;
        }
    };

    // Pridať upraviť záznam - volanie cez háčik kvôli kontextu (referencia na documentTypes)
    interface handleCreateHookProps {
        id: number;
        typeId: number;
    }
    const [handleCreateHook, setHandleCreateHook] = useState<handleCreateHookProps | undefined>(undefined);
    useEffect(() => {
        if (handleCreateHook === undefined)
            return;
        handleCreate(handleCreateHook.id, handleCreateHook.typeId);
    }, [handleCreateHook]);

    // Pridať upraviť záznam
    const handleCreate = (id: number, typeId: number) => {
        var docType = documentTypes.find(documentType => documentType.id === typeId);
        setDocumentCreate(prev => ({
            ...prev,
            id: id,
            documentType: docType ?? {},
            open: true
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: gridSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie záznamov
    const loadData = () => {
        // Aktivujem automatické ukladanie filtra po zmene
        if (rowsFilterAutosave === false) {
            setRowsFilterAutosave(true);
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'stockmovement', {
                params: rowsFilter
            })
            .then(response => {
                setRows(response.data?.list ?? []);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => {
        setRowsFilter(prev => ({ ...prev }));
    }

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    // Číselníky pre filter
    const [departments, setDepartments] = useState<Department[]>([]);
    const [departmentsGrouped, setDepartmentsGrouped] = useState<any[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [warehousesFiltered, setWarehousesFiltered] = useState<Warehouse[]>([]);

    // Načítam oddelenia
    const loadDepartments = () => {
        axios
            .get(AppConfig.ApiUri + 'department')
            .then(response => setDepartments((response.data as Department[]).filter(item => item.actived)));
    };
    useEffect(() => loadDepartments(), []);

    // Načítam sklady
    const loadWarehouses = () => {
        axios
            .get(AppConfig.ApiUri + 'warehouse', { params: { withRelations: true } })
            .then(response => setWarehouses(response.data as Warehouse[]));
    };
    useEffect(() => loadWarehouses(), []);

    // Automaticky odfiltrujem sklady z ktorých môžem vyberať (ak pre daný druh dokladu nie sú povolené, tak je zoznam prázdny)
    useEffect(() => {

        // Odfiltrovaný zoznam (iba vlastné sklady)
        let filtered: Warehouse[] = [...warehouses].filter(warehouse => (warehouse.type ?? 0) === 0);

        // Ak je vybrané stredisko, tak môžem zobraziť len sklady, ktoré patria pod dané stredisko
        if (filtered.length > 0 && (rowsFilter.departmentId ?? 0) > 0) {
            filtered = filtered.filter(warehouse => (warehouse.departments ?? []).includes((rowsFilter.departmentId ?? 0)));
        }

        // Ak mám nastavený sklad, ktorý už nefiguruje v zozname, tak ho musím zrušiť (toto platí len ak už bol načítaný zoznam všetkých skladov)
        if (warehouses.length > 0) {
            setRowsFilter(prev => {
                if ((prev.warehouseId ?? 0) > 0 && filtered.find(warehouse => warehouse.id === prev.warehouseId) === undefined) {
                    return { ...prev, warehouseId: 0 };
                }
                return prev;
            });
        }

        setWarehousesFiltered(filtered);
    }, [warehouses, rowsFilter.departmentId]);

    // Automaticky získam zoznam prevádzok zo stredísk
    useEffect(() => {
        var items: any[] = [];
        var branchNames = Array.from(new Set(departments?.map(item => item.branchName ?? '') ?? []));
        branchNames?.forEach(branchName => {
            items.push(<ListSubheader key={branchName} component="div" disableSticky sx={{ maxHeight: '25px', lineHeight: '25px', background: 'none', fontSize: 'small' }}>{branchName}</ListSubheader>);
            departments?.filter(item => item.branchName === branchName)?.forEach(item => items.push(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>));
        });
        setDepartmentsGrouped(items);
    }, [departments]);

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={1} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Stredisko a sklad */}
                        <Grid item container xs md={3} sx={{ pr: 1 }} columnSpacing={.5}>
                            <Grid item xs={6}>
                                <Select size="small" value={rowsFilter.departmentId ?? 0} fullWidth onChange={(e) => { setRowsFilter(prev => ({ ...prev, page: 0, departmentId: ConvertToInt(e.target.value) })); }}>
                                    <MenuItem value={0}>{(rowsFilter.departmentId ?? 0) > 0 ? 'Všetko' : 'Stredisko'}</MenuItem>
                                    {departmentsGrouped.map(item => item)}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select size="small" value={rowsFilter.warehouseId ?? 0} fullWidth onChange={(e) => { setRowsFilter(prev => ({ ...prev, page: 0, warehouseId: ConvertToInt(e.target.value) })); }}>
                                    <MenuItem value={0}>{(rowsFilter.warehouseId ?? 0) > 0 ? 'Všetko' : 'Sklad'}</MenuItem>
                                    {warehousesFiltered?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                </Select>
                            </Grid>
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        ...props?.filter,
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        branchId: prev.branchId,
                                        departmentId: prev.departmentId,
                                        warehouseId: prev.warehouseId
                                    }));
                                }}
                               
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setRowsFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Box sx={{
                            height: '100%', width: '100%',
                            '& a': {
                                color: (theme) => theme.palette.text.primary,
                                textDecorationColor: (theme) => theme.palette.text.primary,
                            },
                            '& .gridstyle-inbound': {
                                color: (theme) => theme.palette.success.light,
                                '& a': {
                                    color: (theme) => theme.palette.success.light,
                                    textDecorationColor: (theme) => theme.palette.success.light,
                                }
                            },
                            '& .gridstyle-negative': {
                                color: (theme) => theme.palette.mode === 'dark' ? '' : theme.palette.error.light,
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? theme.palette.error.light : '#fffcd0'
                            },
                        }}>
                            <DataGrid
                                getRowClassName={(params) => {
                                    if ((params.row.inbound ?? false) === true)
                                        return 'gridstyle-inbound';
                                    return '';
                                }}
                                getRowId={row => row.id}
                                density={density}
                                checkboxSelection
                                disableSelectionOnClick
                                columns={columns}
                                rows={rows}
                                rowCount={rowsCount}

                                pagination
                                paginationMode="server"
                                page={rowsFilter.page}
                                pageSize={rowsFilter.pageSize}

                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                                onPageSizeChange={(pageSize) => {
                                    setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                    gridSettings.pageSizeChanged(pageSize);
                                }}

                                sortingMode="server"
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}

                                localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                loading={loading}

                                // Dvoj-klik (úprava)
                                onCellDoubleClick={(e) => {
                                    handleDoubleClick(e.row.documentId, e.row.documentTypeId);
                                }}

                                // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                onCellKeyDown={(e, c) => {
                                    if (c.code === 'Enter') {
                                        c.preventDefault();
                                        c.stopPropagation();
                                        setHandleCreateHook({
                                            id: e.row.documentId as number,
                                            typeId: e.row.documentTypeId as number
                                        });
                                        return;
                                    }
                                }}

                                // Filtrácia
                                filterModel={filterModel}
                                onFilterModelChange={e => setFilterModel(e)}

                                // Vybrané záznamy
                                selectionModel={rowsSelected}
                                onSelectionModelChange={e => setRowsSelected(e)}

                                // Stĺpce (automatické ukladanie nastavení)
                                onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                            />
                        </Box>
                    </div>
                </ContentBottom>
            </Content>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <DocumentCreate {...stockMovementCreate} documentTypes={documentTypes} />

            {/* Formulár pre filtráciu */}
            <StockMovementsFiltering {...stockMovementsFiltering} />

            {/* Tlač dokladu */}
            <ReportDocument {...documentPrint} />
        </>
    )
}

export default StockMovements;