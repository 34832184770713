/*
    Ako to funguje
    ---------------------
    BarcodeContext obsahuje EventEmitter pre oznamovanie zachyteného kódu na ktorého odber sa môžu komponenty prihlásiť.
    BarcodeProvider zaobaluje komponenty aplikácie aby poskytoval BarcodeContext naprieč aplikáciou.
    BarcodeReader zachytáva čiarový kód z čítačky a následne spustí EventEmitter pre oznámenie zachyteného kódu.
    BarcodeIndicator indikuje, či je možné zachytiť čiarový kód podľa aktívneho stavu okna.
    
    Poznámky
    ---------------------
    Funkcia "subscribe" má na vstupe nepovinný parameter priorita, ktorá určuje poradie oznámaní o novom kóde.

    Integrácia do aplikácie (root)
    ---------------------
    
    <BarcodeProvider>
        {RenderApp()}
    </BarcodeProvider>

    Použitie v komponentoch
    ---------------------

    const barcodeContext = useContext(BarcodeContext);
    const [barcode, setBarcode] = useState<string>('');

    useEffect(() => {
        if (!barcodeContext)
            return;
        const unsubscribe = barcodeContext.barcode.subscribe((code, stopPropagation) => {
            if (code.startsWith('123')) {
                setBarcode(code);
                stopPropagation();
            }
        });
        return unsubscribe;
    }, [barcodeContext]);

*/

import { useContext, useEffect, useState, createContext, ReactNode, useRef } from 'react';
import { EventEmitter } from './EventEmitter';

export interface BarcodeContextModel {
    barcode: EventEmitter<string>;
}

/**
 * Poskytovateľ kontextu čiarového kódu
 */
export interface BarcodeProviderProps extends BarcodeReaderProps {
    children: ReactNode;
}
export const BarcodeContext = createContext<BarcodeContextModel | null>(null);
export const BarcodeProvider = (props: BarcodeProviderProps) => {
    const barcodeEmitter = useRef(new EventEmitter<string>());
    return (
        <BarcodeContext.Provider value={{ barcode: barcodeEmitter.current }}>
            <BarcodeReader {...props} />
            {props.children}
        </BarcodeContext.Provider>
    );
};
export default BarcodeProvider;

/**
 * Indikátor čítačky čiarových kódov
 */
export interface BarcodeIndicatorProps {
    active?: ReactNode;
    inactive?: ReactNode;
}
export const BarcodeIndicator = (props: BarcodeIndicatorProps) => {
    const [isActive, setIsActive] = useState<boolean>(true);

    useEffect(() => {
        const handleVisibilityChange = () => setIsActive(!document.hidden);
        const handleFocus = () => setIsActive(true); // Okno získalo fokus
        const handleBlur = () => setIsActive(false); // Okno stratilo fokus

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    return (
        <>
            {isActive === true && (props.active ?? <span style={{ color: 'green' }}>Active</span>)}
            {isActive === false && (props.inactive ?? <span style={{ color: 'red' }}>Inactive</span>)}
        </>
    )
};

/**
 * Čítačka čiarových kódov
 */
export interface BarcodeReaderProps {
    minLength?: number; // Minimálny počet znakov (default: 4)
    keyPressTime?: number; // Maximálny čas medzi údermi kláves (default: 75)
}

const BarcodeReader = (props: BarcodeReaderProps) => {
    interface BarcodeBuffer {
        code: string;
        lastTime: number;
        target: HTMLElement | null;
        targetValue: string;
    }
    const barcodeContext = useContext(BarcodeContext);
    const [barcodeCaptured, setBarcodeCaptured] = useState('');
    const [barcodeBuffer, setBarcodeBuffer] = useState<BarcodeBuffer>({ code: '', lastTime: 0, target: null, targetValue: '' });

    useEffect(() => {
        if (barcodeCaptured.length > 0) {
            barcodeContext?.barcode.emit(barcodeCaptured);
            setBarcodeCaptured('');
        }
    }, [barcodeCaptured]);

    const inputTextTypes = ['textarea', 'input/text', 'input/password'];
    const keyMap: { [key: string]: string } = {
        Digit1: '1',
        Digit2: '2',
        Digit3: '3',
        Digit4: '4',
        Digit5: '5',
        Digit6: '6',
        Digit7: '7',
        Digit8: '8',
        Digit9: '9',
        Digit0: '0',
        Minus: '_'
    };

    const getTargetInfo = (target: HTMLElement): string => {
        const targetTagName = target.tagName.toLowerCase();
        if (targetTagName === 'input') {
            const inputElement = target as HTMLInputElement;
            const inputType = inputElement.type.toLowerCase();
            return `${targetTagName}/${inputType}`;
        }
        return targetTagName;
    };
    const cleanTarget = (target: HTMLElement, value: string) => {
        if (target && inputTextTypes.includes(getTargetInfo(target))) {
            setInputValue(target as HTMLInputElement | HTMLTextAreaElement, value);
        }
    }
    const setInputValue = (input: HTMLInputElement | HTMLTextAreaElement, newValue: string) => {
        setTimeout(() => {
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
            if (nativeInputValueSetter) {
                nativeInputValueSetter.call(input, newValue);
                const event = new Event('input', { bubbles: true });
                input.dispatchEvent(event);
            }
        }, 0);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event?.code === undefined) {
            return;
        }
        setBarcodeBuffer((prev) => {
            const buffer = { ...prev };
            const bufferClear = () => {
                buffer.code = '';
                buffer.target = null;
                buffer.targetValue = '';
            }

            const currentTime = Date.now();
            if (currentTime - buffer.lastTime > (props.keyPressTime ?? 75)) {
                bufferClear();
            }
            buffer.lastTime = currentTime;
            buffer.target = event.target as HTMLElement;

            const keyMapped = keyMap[event.code];
            const keyChar = event.key.length === 1 ? keyMapped ?? event.key : '';

            if (buffer.code.length === 0) {
                if (buffer.target && inputTextTypes.includes(getTargetInfo(buffer.target))) {
                    const targetElement = buffer.target as HTMLInputElement | HTMLTextAreaElement;
                    buffer.targetValue = targetElement.value;
                }
            }

            if (event.key !== 'Enter') {
                buffer.code = buffer.code + keyChar;
                return buffer;
            }

            if (buffer.code.length >= (props.minLength ?? 3)) {
                event.preventDefault();
                event.stopPropagation();
                cleanTarget(buffer.target, buffer.targetValue);
                setBarcodeCaptured(buffer.code);
            }

            bufferClear();
            return buffer;
        });
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown, { capture: true });
        return () => {
            window.removeEventListener('keydown', handleKeyDown, { capture: true });
        };
    }, []);

    return null;
}