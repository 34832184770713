import React, { useEffect, useMemo, useState } from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { PriceGroup, PriceList, ProductPriceList, Unit, Warehouse } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, MathRound2 } from '../../utility/Number';
import { FormatDecimal } from '../../utility/Format';

// Komponenty
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, IconButton, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';
import DeleteIcon from '@mui/icons-material/Delete';
import FunctionsIcon from '@mui/icons-material/Functions';
import EuroIcon from '@mui/icons-material/Euro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductCreateSupplier from './ProductCreateSupplier';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& td': {
        padding: theme.spacing(0.2),
    },
    '& th': {
        paddingY: theme.spacing(0.2),
        lineHeight: 1.1,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreatePriceProps extends ProductCreateComponentProps {
    warehouses: Warehouse[];
    priceLists: PriceList[];
    priceGroups: PriceGroup[];
    vatRates: number[];
    units: Unit[];
};

const ProductCreatePrice = (props: ProductCreatePriceProps) => {

    // Skupiny skladov
    const [warehouseGroups, setWarehouseGroups] = useState<string[]>([]);

    // Automaticky získam zoznam skupín
    useEffect(() => setWarehouseGroups(Array.from(new Set(props.warehouses?.map(item => item.warehouseGroupName ?? '') ?? []))), [props.warehouses]);

    // Zmeny vo formulári
    const handleChangePriceList = (id: number, property: string, value: any) => {
        props.setSource(prev => {
            const item: ProductPriceList = { ...prev?.priceLists?.find(item => item.priceListId === id), priceListId: id, [property]: value };
            const next = { ...prev, priceLists: [...prev?.priceLists?.filter(item => item.priceListId !== id) ?? [], item] };
            return next;
        });
    }

    // Pridať, upraviť alebo vymazať množstevnú zľavu
    const handleChangeVolumeDiscounts = (index: number, property: string, value: any) => {
        props.setSource(prev => {
            let changed = prev.volumeDiscounts ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...prev, volumeDiscounts: changed };
        });
    }
    const handleCreateVolumeDiscounts = () => {
        props.setSource(prev => ({ ...prev, volumeDiscounts: [...(prev.volumeDiscounts ?? []), { priceListId: 0 }] }));
    }
    const handleDeleteVolumeDiscounts = (index: number) => {
        props.setSource(prev => {
            let changed = prev.volumeDiscounts ?? [];
            return { ...prev, volumeDiscounts: changed.filter(deleted => deleted !== changed[index]) ?? [] };
        });
    }

    // Funkcia pre aplikovanie zľavy, ak je zľava nulová tak vrátim pôvodnú cenu
    const applyDiscount = (price: number, discount: number): number => MathRound2(discount === 0 || price === 0 ? price : price * ((100 - discount) / 100));

    // Ohodnotenie zásob a ohodnotené množstvo na vlastných skladoch (skladová cena, posledná nákupná cena)
    const totalValuationQuantity = useMemo<number>(() => props.source.stocks?.filter(stock => (props.warehouses.find(warehouse => warehouse.id === stock.warehouseId)?.type ?? 0) === 0)?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? 0, [props.source.stocks]);
    const totalValuation = useMemo<number>(() => props.source.stocks?.reduce((sum, current) => sum + ((current?.priceWac ?? 0) * (current?.quantity ?? 0)), 0) ?? 0, [props.source.stocks]);
    const totalValuationPurchaseLast = useMemo<number>(() => props.source.stocks?.reduce((sum, current) => sum + ((current?.pricePurchaseLast ?? 0) * (current?.quantity ?? 0)), 0) ?? 0, [props.source.stocks]);

    // Vážený priemer (skladová cena, nákupná cena)
    const totalPriceWac = totalValuation / totalValuationQuantity;
    const totalPricePurchaseLast = totalValuationPurchaseLast / totalValuationQuantity;

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '18%' }}>Cena {(props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')}</TableCell>
                                    <TableCell sx={{ width: '18%' }}>Akciová cena {(props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')}</TableCell>
                                    <TableCell sx={{ width: '12%', paddingRight: 0 }}>Min. obj. množstvo</TableCell>
                                    <TableCell sx={{ width: '12%', paddingRight: 0 }}>Max. obj. množstvo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow>
                                    <TableCell component="th" scope="row">
                                        <strong>Základná cena</strong>
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth required size="small" name="price" type="text" variant="outlined" autoComplete="off" value={props.source.price ?? ''} placeholder='0'
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="priceDiscount" type="text" variant="outlined" autoComplete="off" value={props.source.priceDiscount ?? ''} placeholder='0'
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="cartMinimalQuantity" type="text" variant="outlined" autoComplete="off" value={props.source.cartMinimalQuantity ?? ''} sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ChevronRightIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="cartMaximalQuantity" type="text" variant="outlined" autoComplete="off" value={props.source.cartMaximalQuantity ?? ''} sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ChevronLeftIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                </StyledTableRow>

                                {props.priceLists?.map((pricelist, index) => {
                                    const priceListItem = props.source.priceLists?.find(item => item.priceListId === pricelist.id);
                                    return (
                                        <StyledTableRow>
                                            <TableCell component="th" scope="row">
                                                <strong>{pricelist.name}</strong><br />
                                                zľava {pricelist.discount}% alebo:
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="price" type="text" variant="outlined" autoComplete="off"
                                                    placeholder={'= ' + applyDiscount(props.source.price ?? 0, pricelist.discount ?? 0).toString()}
                                                    value={priceListItem?.price ?? ''}
                                                    onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="discount" type="text" variant="outlined" autoComplete="off"
                                                    placeholder={'= ' + applyDiscount(props.source.priceDiscount ?? 0, pricelist.discount ?? 0).toString()}
                                                    value={priceListItem?.discount ?? ''}
                                                    onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="cartMinimalQuantity" type="text" variant="outlined" autoComplete="off" sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                                    value={priceListItem?.cartMinimalQuantity ?? ''}
                                                    onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <ChevronRightIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="cartMaximalQuantity" type="text" variant="outlined" autoComplete="off" sx={{ backgroundColor: (theme) => theme.palette.action.hover }}
                                                    value={priceListItem?.cartMaximalQuantity ?? ''}
                                                    onChange={(e) => { handleChangePriceList((pricelist?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <ChevronLeftIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                })}

                                <StyledTableRow>
                                    <TableCell component="th" scope="row">
                                        Odporúčaná cena
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="priceRecommended" type="text" variant="outlined" autoComplete="off" value={props.source.priceRecommended ?? ''} placeholder='0'
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component="th" scope="row">
                                        Nákupná cena (bez DPH) / DPH
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" name="pricePurchase" type="text" variant="outlined" autoComplete="off" value={props.source.pricePurchase ?? ''} placeholder='0'
                                            onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                    <TableCell>
                                        <Select sx={{ maxWidth: '50%' }} fullWidth labelId="product-create-purchasevatrate" size="small" value={props.source.pricePurchaseVatRate ?? 0} name="pricePurchaseVatRate"
                                            onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                            inputRef={input => props.autoFocus === 'pricePurchaseVatRate' && props.setFocus(input)}>
                                            {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                            {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                            {(props.source.priceVatRate ?? 0) > 0 && !(props.vatRates ?? []).includes(props.source.priceVatRate ?? 0) && <MenuItem value={props.source.priceVatRate ?? 0}>{props.source.priceVatRate} %</MenuItem>}
                                        </Select>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <TableCell component="th" scope="row">
                                        Cenová skupina
                                    </TableCell>
                                    <TableCell>
                                        <Select fullWidth size="small" labelId="product-create-pricegroup" value={props.source.priceGroupId ?? 0} name="priceGroupId"
                                            onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                            inputRef={input => props.autoFocus === 'priceGroupId' && props.setFocus(input)}>
                                            <MenuItem value={0}>Predvolená</MenuItem>
                                            {props.priceGroups.map(item => (<MenuItem key={item.id} value={item.id}>{item.name ?? ''}</MenuItem>))}
                                        </Select>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </StyledTableRow>

                            </TableBody>
                        </Table>

                        <Alert severity='info' sx={{ mt: 2 }}>
                            <strong>Limity</strong> obj. množstva platia len v objednávkovom systéme zákazníka, nie v administrácii. Ak cenník nemá limity, použijú sa limity základnej ceny (ak existujú).
                        </Alert>
                    </div>
                </div>
            </Paper>

            {props.warehouses?.find(stock => (stock.type ?? 0) === 0) !== undefined && (
                <Paper sx={{ p: 2, mt: 2 }}>
                    <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                        <div style={{ flexGrow: 1 }}>
                            <Table size="small" sx={{ minWidth: '500px' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{ width: '20%' }} align='right'><strong>Skladová cena</strong> (bez DPH)</TableCell>
                                        <TableCell sx={{ width: '20%' }} align='right'>Posledný nákup (bez DPH)</TableCell>
                                        <TableCell sx={{ width: '20%' }} align='right'>Ocenenie zásob</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {warehouseGroups?.map((groupName, index) => (
                                        <React.Fragment key={index}>
                                            {props.warehouses?.filter(stock => (stock.type ?? 0) === 0 && (stock.warehouseGroupName ?? '') === groupName)?.map((stock, index) => {
                                                var stockItem = props.source.stocks?.find(item => item.warehouseId === stock.id);
                                                return (
                                                    <React.Fragment key={stock.id}>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                {groupName.length > 0 ? groupName + ' / ' : ''}
                                                                {stock.name}
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {FormatDecimal(stockItem?.priceWac ?? 0, 4)}
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {FormatDecimal(stockItem?.pricePurchaseLast ?? 0, 4)}
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {FormatDecimal((stockItem?.priceWac ?? 0) * (stockItem?.quantity ?? 0), 2)}
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </React.Fragment>
                                    ))}
                                    <TableRow sx={{ '& td, & th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Celkom / Vážený priemer</TableCell>
                                        <TableCell align='right'>{FormatDecimal(totalPriceWac ?? 0, 4)}</TableCell>
                                        <TableCell align='right'>{FormatDecimal(totalPricePurchaseLast ?? 0, 2)}</TableCell>
                                        <TableCell align='right'>{FormatDecimal(totalValuation, 2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                            <Alert severity='info' sx={{ mt: 2 }}>
                                <strong>Skladová cena</strong> sa počíta ako vážený priemer všetkých nákupných cien z aktuálne dostupných zásob pre každý sklad samostatne.
                            </Alert>
                        </div>
                    </div>
                </Paper>
            )}

            <Box sx={{ mt: 2 }}>
                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('suppliers')} onChange={() => { props.onToggleAccordion('suppliers') }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Dodávatelia ({props.source.suppliers?.length ?? 0})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductCreateSupplier {...props} />
                    </AccordionDetails>
                </Accordion>

                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={props.isAccordionExpanded('volumediscounts')} onChange={() => { props.onToggleAccordion('volumediscounts') }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Množstevné zľavy ({props.source.volumeDiscounts?.length ?? 0})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(props.source.volumeDiscounts?.length ?? 0) > 0 &&
                            <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <Table size="small" sx={{ minWidth: '500px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Cenník</TableCell>
                                                <TableCell>Od množstva</TableCell>
                                                <TableCell>Zľava %</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.source.volumeDiscounts?.map((discount, index) => (
                                                <TableRow key={index} sx={{ 'td': { padding: .2 }, 'th': { paddingY: .2, lineHeight: 1.2 }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell sx={{ width: '33%', p: 0.5, pl: 0 }}>
                                                        <Select fullWidth size="small" name="priceListId"
                                                            value={discount.priceListId ?? 0}
                                                            onChange={(e) => { handleChangeVolumeDiscounts(index, e.target.name, e.target.value) }}>
                                                            <MenuItem key={0} value={0}>Žiadny (základná cena)</MenuItem>
                                                            {props.priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField required fullWidth size="small" name="minimalQuantity" type="text" variant="outlined" autoComplete="off" value={discount.minimalQuantity ?? ''}
                                                            onChange={(e) => { handleChangeVolumeDiscounts(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Množstvo od ktorého je uplatnená zľava">
                                                                            <FunctionsIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField required fullWidth size="small" name="discount" type="text" variant="outlined" autoComplete="off" value={discount.discount ?? ''}
                                                            onChange={(e) => { handleChangeVolumeDiscounts(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Zľava, ktorá je uplatnená po dosiahnutí zadaného množstva">
                                                                            <PercentIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                        <IconButton aria-label="Vymazať" onClick={() => handleDeleteVolumeDiscounts(index)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        }
                        <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => handleCreateVolumeDiscounts()}>Pridať zľavu</Button>
                        <Alert severity="warning" sx={{ mt: 1 }}>
                            Ak je pri produkte nastavená akákoľvek množstevná zľava (bez ohľadu na vybraný cenník), tak pre daný produkt neplatia žiadne iné nastavenia množstevných zliav.
                            V tomto prípade je potrebné nastaviť všetky požadované množstevné zľavy pre všetky cenníky priamo pri produkte (nastavenia z cenníkov sú ignorované).
                        </Alert>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    )
};

export default ProductCreatePrice;
