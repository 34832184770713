import { FormEvent, useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../../AppRoutes';

// Modely
import { PriceList, PriceListSettings } from '../../../models/Models';

// Utility
import { ResponsivePoint as HidePoint } from '../../../utility/ResponsivePoint';
import { FindText } from '../../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../../utility/DataGrid';
import { useQuery } from '../../../utility/URL';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Alert, AlertColor, Box, Button, Divider, FormControlLabel, Grid, IconButton, ListItemIcon, Menu, Paper, Switch, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import Search from '../../../components/Search';
import PriceListCreate, { PriceListCreateProps } from './PriceListCreate';
import { Content, ContentTop, ContentBottom } from '../../../layout/Content';
import DataGridDensity from '../../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Komponent pre zoznam 
const PriceLists = () => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [priceListCreate, setPriceListCreate] = useState<PriceListCreateProps>({
        open: false,
        keepMounted: true,
        onSave: () => loadData(),
        onClose: () => setPriceListCreate(prev => ({ ...prev, open: false }))
    });
    const [settings, setSettings] = useState<PriceListSettings>({
        priceIncludeVat: false
    });

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'pricelists' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'actived', headerName: 'Aktívny', hide: HidePoint().sm, width: 70, type: 'boolean' },
        { field: 'name', headerName: 'Názov', hide: false, minWidth: 50, flex: 0.8 },
        { field: 'discount', headerName: 'Zľava (%)', hide: HidePoint().md, minWidth: 80, flex: 0.2, align: 'center', headerAlign: 'center' },
        { field: 'minimalPrice', headerName: 'Minimálna objednávka', hide: HidePoint().md, minWidth: 80, flex: 0.2, align: 'center', headerAlign: 'center' },
        { field: 'allowVoucher', headerName: 'Použitie poukážky', hide: HidePoint().md, width: 80, flex: 0.2, type: 'boolean' },
        { field: 'allowVolumeDiscount', headerName: 'Objemové zľavy', hide: HidePoint().md, width: 80, flex: 0.2, type: 'boolean' },
        {
            field: 'createdDate', headerName: 'Vytvorený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => (params?.value as Date).toLocaleDateString() ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<PriceList[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<PriceList[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setPriceListCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.PRICE_LISTS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'pricelist/' + id)
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => r.id !== id)]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'pricelist/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            setRows(prev => [...prev.filter(r => !ids.includes(r?.id ?? 0))]);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number)
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pricelist')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    // Načítam nastavenia
    const loadSettings = () => {
        axios
            .get(AppConfig.ApiUri + 'pricelist/settings')
            .then(response => {
                setSettings(response.data);
            });
    };
    useEffect(() => loadSettings(), []);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uložím nastavenia
    const handleSaveSettings = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        axios
            .post(AppConfig.ApiUri + 'pricelist/settings', settings)
            .then(response => {
                if (response.data === true) {
                    setWarning({ ...EmptyWarning, color: 'success', text: 'Nastavenia boli uložené!' });
                }
                else {
                    setWarning({ ...EmptyWarning, color: 'error', text: 'Nastavenia sa nepodarilo uložiť!' });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Spracujem zmeny nastavení
    const handleChangeSettings = (property: string, value: any) => {
        setSettings(prev => ({ ...prev, [property]: value }));
    }

    return (
        <>
            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <PriceListCreate {...priceListCreate} />

            {/* Obsah */}
            <Content>
                <ContentTop>

                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={12} md lg>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový cenník</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber</MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>
                        
                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }} />
                        </Grid>
                    </Grid>

                </ContentTop>
                <ContentBottom>

                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>
                            {/* Nastavenia */}
                            <Grid item xs={12} md={4} lg={3} xl={2}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ p: 2, position: 'absolute', width: '100%', height: '100%' }} component="form" onSubmit={handleSaveSettings}>
                                        <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>

                                            {/* Nastavenia */}
                                            <Grid container columnSpacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" gutterBottom component="div" pl={1} pt={1}>Nastavenia</Typography>
                                                </Grid>
                                                <Grid item xs={12} mb={0}>
                                                    <FormControlLabel control={<Switch checked={settings.priceIncludeVat} name="priceIncludeVat" onChange={(e) => { handleChangeSettings(e.target.name, e.target.checked) }} />} label="Ceny sú vrátane DPH" />
                                                </Grid>
                                                <Grid item xs={12} mb={1}>
                                                    <Alert severity="info">
                                                        {settings.priceIncludeVat && <>Všetky predajné ceny sú vrátane DPH, výpočet čiastky DPH teda bude vykonaný výpočtom zhora (B2C).</>}
                                                        {!settings.priceIncludeVat && <>Všetky predajné ceny sú bez DPH, výpočet čiastky DPH teda bude vykonaný výpočtom zdola (B2B).</>}
                                                    </Alert>
                                                </Grid>
                                            </Grid>
                                            
                                            <Button disabled={loading} type="submit" variant="outlined" size="medium" color="secondary">Uložiť</Button>
                                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                            {/* Produkty */}
                            <Grid item xs={12} sm lg xl>
                                <Box sx={{ height: '100%', width: '100%' }}>
                                    <DataGrid
                                        getRowId={row => row.id}
                                        density={density}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        columns={columns}
                                        rows={rowsFiltered}
                                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                        loading={loading}

                                        // Dvoj-klik (úprava)
                                        onCellDoubleClick={(e) => {
                                            handleCreate(e.row.id, false, e.field);
                                        }}

                                        // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                        onCellKeyDown={(e, c) => {
                                            if (c.code === 'Enter' && c.ctrlKey) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, true, e.field);
                                                return;
                                            }
                                            if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, false, e.field);
                                                return;
                                            }
                                            if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleDelete(e.row.id, e.row.name);
                                                return;
                                            }
                                        }}

                                        // Filtrácia
                                        filterModel={filterModel}
                                        onFilterModelChange={e => setFilterModel(e)}

                                        // Vybrané záznamy
                                        selectionModel={rowsSelected}
                                        onSelectionModelChange={e => setRowsSelected(e)}

                                        // Stĺpce (automatické ukladanie nastavení)
                                        onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>

                </ContentBottom>
            </Content>

        </>
    )
}

export default PriceLists;