import { useCallback, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { WebPage, WebPageBlockModules, WebPageBlockModuleTypes, WebPageContent } from '../../models/Models';

// Utility
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { FindText } from '../../utility/Search';
import { Settings as DataGridColumnSettings } from '../../utility/DataGrid';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import Search from '../../components/Search';
import DataGridDensity from '../../components/DataGridDensity';

export interface WebPageCreateContentProps {
    open: boolean;
    module: WebPageBlockModuleTypes;
    keepMounted?: boolean;
    onSelect: (content: number) => void;
    onClose: () => void;
};

const WebPageCreateContent = (props: WebPageCreateContentProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'webpagecontent' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'actived', headerName: 'Aktívne', hide: HidePoint().sm, width: 70, type: 'boolean' },
        { field: 'title', headerName: 'Názov', hide: false, minWidth: 80, flex: 0.8, align: 'left', headerAlign: 'center' },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 100, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <Button size="small" variant="contained" onClick={() => { props.onSelect(params.value ?? 0); }}>Vybrať</Button>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<WebPageContent[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsFiltered, setRowsFiltered] = useState<WebPage[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            setSearch('');
            return;
        }
        setRows([]);
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'webpage/content/', {
                params: {
                    'module': props.module
                }
            })
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.open, props.module]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box>
                    <DialogTitle>
                        {WebPageBlockModules.find(module => module.type === props.module)?.title ?? ''}
                    </DialogTitle>
                    <DialogContent sx={{ height: '75vh' }}>

                        {/* Obsah */}
                        <Content>
                            <ContentTop>
                                {/* Horný panel */}
                                <Grid container alignItems="center">

                                    <Grid item xs md>
                                        <DataGridDensity onDensityChanged={setDensity} />
                                    </Grid>

                                    {/* Vyhľadávanie */}
                                    <Grid item xs={7} sx={{ mt: { xs: 1, md: 0 } }}>
                                        <Search onSearch={s => setSearch(s)}
                                            onClear={() => {
                                                setSearch('');
                                                setFilterModel({ items: [], linkOperator: undefined });
                                            }}
                                            />
                                    </Grid>
                                </Grid>
                            </ContentTop>
                            <ContentBottom>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <DataGrid
                                        density={density}           
                                        disableSelectionOnClick
                                        columns={columns}
                                        rows={rowsFiltered}
                                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                        loading={loading}

                                        // Dvoj-klik (výber)
                                        onCellDoubleClick={(e) => {
                                            props.onSelect(e.row.id ?? 0);
                                        }}

                                        // Klávesnica (výber)
                                        onCellKeyDown={(e, c) => {
                                            if (c.code === 'Enter') {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                props.onSelect(e.row.id ?? 0);
                                                return;
                                            }
                                        }}

                                        // Filtrácia
                                        filterModel={filterModel}
                                        onFilterModelChange={e => setFilterModel(e)}

                                        // Vybrané záznamy
                                        selectionModel={rowsSelected}
                                        onSelectionModelChange={e => setRowsSelected(e)}

                                        // Stĺpce (automatické ukladanie nastavení)
                                        onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                                    />
                                </div>
                            </ContentBottom>
                        </Content>




                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default WebPageCreateContent;