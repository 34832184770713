import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { CustomersFilter, CustomerLite } from '../../models/Models';

// Utility
import Debounce from '../../utility/Debounce';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';

// Komponenty
import { Backdrop, Button, CircularProgress, Divider, Grid, IconButton, ListItemIcon, Menu } from '@mui/material';
import { DataGrid, GridColDef, GridDensity, GridFilterModel, GridRenderCellParams, GridRowId, GridSortItem, GridSortModel, GridValueFormatterParams, GridValueGetterParams, skSK } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import CustomerCreate, { CustomerCreateProps, CustomerCreateSaveAction } from './CustomerCreate';
import CustomersFiltering, { CustomersFilteringProps } from './CustomersFiltering';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import { useHistory } from 'react-router-dom';

// Rozšírenie pre vlastný index zoradenia
interface GridSortModelIndex extends GridSortItem {
    index: number;
}

// Vstupné parametre
export interface CustomersProps {
    filter?: CustomersFilter;
    onSelectionChanged?: (rows: CustomerLite[], confirm: boolean) => void;
}

// Komponent pre zoznam užívateľov
const Customers = (props?: CustomersProps) => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [customerCreate, setCustomerCreate] = useState<CustomerCreateProps>({
        open: false,
        keepMounted: true,
        onSave: (id?: number, action?: CustomerCreateSaveAction) => {
            // Obnovím zoznam
            setRowsFilter(prev => ({ ...prev }));

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === CustomerCreateSaveAction.SaveAndNew) {
                    handleCreate(0);
                    return;
                }
                if (action === CustomerCreateSaveAction.SaveAndContinue) {
                    handleCreate(id ?? 0);
                    return;
                }
                if (action === CustomerCreateSaveAction.SaveAndNewFromCopy) {
                    handleCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setCustomerCreate(prev => ({ ...prev, open: false }))
    });
    const [customersFiltering, setCustomersFiltering] = useState<CustomersFilteringProps>({
        open: false,
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setCustomersFiltering(prev => ({ ...prev, open: false }))
    });

    // Nastavenia stĺpcov
    const gridSettings = new DataGridColumnSettings({ uid: 'customers' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'actived', headerName: 'Aktívny', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        { field: 'name', headerName: 'Názov', hide: false, minWidth: 60, flex: 0.8 },
        { field: 'phone', headerName: 'Telefón', hide: HidePoint().md, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'email', headerName: 'Email', hide: HidePoint().md, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'domainName', headerName: 'Doména', hide: HidePoint().lg, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'firstname', headerName: 'Meno', hide: true, minWidth: 60, flex: 0.4 },
        { field: 'lastname', headerName: 'Priezvisko', hide: true, minWidth: 60, flex: 0.4 },
        { field: 'company', headerName: 'Firma', hide: true, minWidth: 60, flex: 0.4 },
        { field: 'regId', headerName: 'IČO', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'taxId', headerName: 'DIČ', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'vatId', headerName: 'IČDPH', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'city', headerName: 'Mesto', hide: HidePoint().lg, minWidth: 60, flex: 0.4 },
        {
            field: 'street', headerName: 'Ulica', hide: HidePoint().lg, minWidth: 60, flex: 0.4,
            valueGetter: (params: GridValueGetterParams) => (params.row.street ?? '') + ' ' + (params.row.houseNumber ?? '')
        },
        { field: 'countryName', headerName: 'Krajina', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'priceListName', headerName: 'Cenník', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'customerGroupName', headerName: 'Zákaznícka skupina', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'parentCustomerName', headerName: 'Nadradená firma', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'department', headerName: 'Oddelenie', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'classification', headerName: 'Pracovná pozícia', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'discountLoyalty', headerName: 'Zľava (vernostná)', hide: true, minWidth: 60, flex: 0.2, sortable: false, align: 'center', headerAlign: 'center', type: 'number' },
        { field: 'discountAdditional', headerName: 'Zľava (dodatočná)', hide: true, minWidth: 60, flex: 0.2, sortable: false, align: 'center', headerAlign: 'center', type: 'number' },
        { field: 'discountMaximum', headerName: 'Zľava (maximálna)', hide: true, minWidth: 60, flex: 0.2, sortable: false, align: 'center', headerAlign: 'center', type: 'number' },
        { field: 'discountCurrent', headerName: 'Zľava (aktuálna)', hide: HidePoint().lg, minWidth: 60, flex: 0.2, sortable: false, align: 'center', headerAlign: 'center', type: 'number' },
        {
            field: 'createdDate', headerName: 'Vytvorený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => (params?.value as Date).toLocaleDateString() ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, (params.row.name ?? ''))}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Možnosti zoradenia (index podľa DB, názov stĺpca v gride a jeho smer)
    const columnsSortDefault: number = 1;
    const columnsSort: GridSortModelIndex[] = [
        { index: 0, field: 'id', sort: 'asc' },
        { index: 1, field: 'id', sort: 'desc' },
        { index: 0, field: 'createdDate', sort: 'asc' },
        { index: 1, field: 'createdDate', sort: 'desc' },
        { index: 2, field: 'email', sort: 'asc' },
        { index: 3, field: 'email', sort: 'desc' },
        { index: 4, field: 'firstname', sort: 'asc' },
        { index: 5, field: 'firstname', sort: 'desc' },
        { index: 6, field: 'lastname', sort: 'asc' },
        { index: 7, field: 'lastname', sort: 'desc' },
        { index: 8, field: 'company', sort: 'asc' },
        { index: 9, field: 'company', sort: 'desc' },
        { index: 10, field: 'city', sort: 'asc' },
        { index: 11, field: 'city', sort: 'desc' },
        { index: 12, field: 'updatedDate', sort: 'asc' },
        { index: 13, field: 'updatedDate', sort: 'desc' },
        { index: 14, field: 'street', sort: 'asc' },
        { index: 15, field: 'street', sort: 'desc' },
        { index: 16, field: 'name', sort: 'asc' },
        { index: 17, field: 'name', sort: 'desc' }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setCustomers] = useState<CustomerLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setCustomersCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<CustomersFilter>({ ...props?.filter ?? {}, page: 0, pageSize: gridSettings.pageSizeApply(25), sort: columnsSortDefault });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.domainId ?? 0) !== 0) { count++; }
        if ((rowsFilter.customerGroupId ?? 0) !== 0) { count++; }
        if ((rowsFilter.priceListId ?? 0) !== 0) { count++; }
        if ((rowsFilter.person ?? 0) !== 0) { count++; }
        if ((rowsFilter.actived ?? 0) !== 0) { count++; }
        if ((rowsFilter.phone ?? '') !== '') { count++; }
        if ((rowsFilter.email ?? '') !== '') { count++; }
        if ((rowsFilter.firstname ?? '') !== '') { count++; }
        if ((rowsFilter.lastname ?? '') !== '') { count++; }
        if ((rowsFilter.company ?? '') !== '') { count++; }
        if ((rowsFilter.regId ?? '') !== '') { count++; }
        if ((rowsFilter.taxId ?? '') !== '') { count++; }
        if ((rowsFilter.vatId ?? '') !== '') { count++; }
        if ((rowsFilter.city ?? '') !== '') { count++; }
        if ((rowsFilter.countryId ?? 0) !== 0) { count++; }
        return count;
    };

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            const ids = rowsSelected.map(r => r as number);
            props.onSelectionChanged(rows.filter(item => ids.includes((item.id ?? 0))) ?? [], false);
        }
    }, [rowsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        let sortIndex: number = columnsSortDefault;
        if (sortModel !== undefined && sortModel.length > 0) {
            sortIndex = columnsSort.find(s => s.field === sortModel[0].field && s.sort === sortModel[0].sort)?.index ?? 0;
        }
        if (rowsFilter.sort !== sortIndex) {
            setRowsFilter(prev => ({ ...prev, sort: sortIndex }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Dvoj-klik v zozname
    const handleDoubleClick = (id: number, field: string) => {
        // Ak požadujem výber súboru, tak po dvoj-kliku prenesiem konkrétny záznam
        if (props?.onSelectionChanged) {
            const item = rows.find(item => item.id === id);
            if (item !== undefined) {
                props.onSelectionChanged([item], true);
                return;
            }
        }
        // Predvolená akcia pre úpravu záznamu
        handleCreate(id, false, field);
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number, copy?: boolean, field?: string) => {
        setCustomerCreate(prev => ({
            ...prev,
            id: id,
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.CUSTOMERS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'customer/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'customer/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: gridSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [{
                field: 'street',
                getValue: (row: any) => {
                    return (row?.street ?? '') + ' ' + (row?.houseNumber ?? '');
                }
            }]
        });
    };

    // Funkcia pre načítanie zákazníkov
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customer', {
                params: rowsFilter
            })
            .then(response => {
                setCustomers(response.data?.list ?? []);
                setCustomersCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový partner</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, search: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort
                                    }));
                                }}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setCustomersFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rows}
                            rowCount={rowsCount}

                            pagination
                            paginationMode="server"
                            page={rowsFilter.page}
                            pageSize={rowsFilter.pageSize}

                            rowsPerPageOptions={[10, 25, 50, 100]}
                            onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                            onPageSizeChange={(pageSize) => {
                                setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                gridSettings.pageSizeChanged(pageSize);
                            }}

                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}

                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                handleDoubleClick(e.row.id, e.field);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if (c.code === 'Enter' && c.ctrlKey) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, true, e.field);
                                    return;
                                }
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleCreate(e.row.id, false, e.field);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    handleDelete(e.row.id, e.row.name ?? '');
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </ContentBottom>
            </Content>


            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <CustomerCreate {...customerCreate} />

            {/* Formulár pre filtráciu */}
            <CustomersFiltering {...customersFiltering} />
        </>
    )
}

export default Customers;