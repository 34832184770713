import { FormEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../utility/Number';
import { Grid, TextField, InputAdornment, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export interface InputBoxProps {
    children?: any;
    value?: string | number;
    valueType: 'text' | 'number';
    valueStep?: number;
    valueMin?: number;
    valueMax?: number;
    open: boolean;
    title: string;
    onConfirm: (value: string | number | undefined) => void;
    onClose: () => void;
}

const InputBox = (props: InputBoxProps) => {

    // Stav
    const [value, setValue] = useState<string | number | undefined>(props.value);
    const [focusActivate, setFocusActivate] = useState<boolean>(false);

    // Zmena hodnoty
    const handleChange = (v: string) => {
        setValue(props.valueType === 'number' ? ConvertToDecimal(v) : v);
    }

    // Potvrdenie hodnoty
    const handleConfirm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onConfirm(value);
        props.onClose();
    }

    // Automaticky nastavím predvolenú hodnotu
    useEffect(() => {
        if (!props.open) {
            return;
        }
        setFocusActivate(true);
        setValue(props.value);
    }, [props.open]);

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }

    return (
        <>
            <Dialog maxWidth="xs" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleConfirm}>
                    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                            <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="center">
                                {props.children}
                                <Grid item xs={12}>
                                    <TextField required fullWidth margin="dense" type="text" variant="outlined" value={value ?? ''}
                                        onChange={(e) => { handleChange(e.target.value) }}
                                        inputRef={input => setFocus(input)}
                                        inputProps={{
                                            maxLength: props.valueType === 'number' ? 18 : undefined,
                                            min: props.valueMin,
                                            max: props.valueMax,
                                            step: props.valueStep,
                                            onInput: props.valueType === 'number' ? ConvertToDecimalOnKeyDown : undefined
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <EditIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Zrušiť</Button>
                        <Button type="submit" variant="contained" autoFocus>Potvrdiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default InputBox