import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Modely
import { Product, ProductSupplier, StockAvailability } from '../../models/Models';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Grid, Paper, Typography } from '@mui/material';
import ButtonList, { Item } from '../../components/ButtonList';

// Ikony
import FlagIcon from '@mui/icons-material/Flag';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TagIcon from '@mui/icons-material/Tag';

export interface ProductCreateBarcodeProps {
    code?: string;
    source: Product;
    setSource: (action: React.SetStateAction<Product>) => void;
    onClose: () => void;
}

enum ItemTypes {
    Property,
    Supplier,
    Unit
}

const ProductCreateBarcode = (props: ProductCreateBarcodeProps) => {

    // Položky na výber
    const [items, setItems] = useState<Item[]>([]);
    const [itemsSuppliers, setItemsSuppliers] = useState<Item[]>([]);
    const [itemsUnits, setItemsUnits] = useState<Item[]>([]);

    // { divider: true, icon: <TagIcon />, key: 6, title: 'Vedľajšia jednotka (balenie)' },
    // { divider: false, icon: <TagIcon />, key: 7, title: 'Kód dodávateľa v zozname' },

    useEffect(() => {
        if (props.code === undefined)
            return;

        let itemsNew: Item[] = [];
        let itemsSuppliersNew: Item[] = [];
        let itemsUnitsNew: Item[] = [];

        // Vygeneruje titulok tlačidla s aktuálným kódom, ktorý sa farebne zvýrazní ak je rovnaký ako nový kód
        const getTitle = (title: string, code?: string): any => (code?.length ?? 0) > 0 ?
            <>{title} <Typography component='span' variant='body2' sx={(theme) => ({ float: 'right', display: 'inline', opacity: (code === props.code ? 1 : .5), color: (code === props.code ? theme.palette.error.main : 'inherit') })}>{code}</Typography></> :
            <>{title}</>;

        // Vyplním zoznam parametrov
        itemsNew = [
            { key: 'ean', title: getTitle('EAN', props.source.ean), divider: true, icon: <QrCodeIcon /> },
            { key: 'plu', title: getTitle('PLU', props.source.plu), divider: true, icon: <QrCodeIcon /> },
            { key: 'code', title: getTitle('Kód produktu', props.source.code), divider: true, icon: <TagIcon /> },
            { key: 'codeSupplier', title: getTitle('Kód dodávateľa', props.source.codeSupplier), divider: true, icon: <TagIcon /> },
            { key: 'mpn', title: getTitle('Kód výrobcu (MPN)', props.source.mpn), divider: false, icon: <TagIcon /> },
        ];

        // Vyplním zoznam dodávateľov
        if ((props.source.suppliers?.length ?? 0) > 0) {
            itemsSuppliersNew = props.source.suppliers?.map(supplier => {
                return { key: supplier.customerId ?? 0, title: getTitle(supplier.customerName ?? '', supplier.code), divider: true, icon: <QrCodeIcon /> }
            }) ?? [];
            if (itemsSuppliersNew.length > 0) {
                itemsSuppliersNew[itemsSuppliersNew.length - 1].divider = false;
            }
        }

        // Vyplním zoznam merných jednotiek
        // ...
        // ...
        // ...

        setItems(itemsNew);
        setItemsSuppliers(itemsSuppliersNew);
        setItemsUnits(itemsUnitsNew);
    }, [props.code, props.source]);

    // Aplikovanie kódu
    const handleSelect = (type: ItemTypes, key: any) => {

        // Základné vlastnosti
        if (type === ItemTypes.Property) {
            props.setSource(prev => ({ ...prev, [key]: props.code }));
        }

        // Zoznam dodávateľov
        if (type === ItemTypes.Supplier) {
            props.setSource(prev => {
                const changed = [...prev?.suppliers ?? []];
                if (changed.find(item => item.customerId === key) !== undefined) {
                    changed.every((item, itemIndex) => {
                        if (item.customerId === key) {
                            changed[itemIndex].code = props.code;
                            return false;
                        }
                        return true;
                    });
                }
                return { ...prev, suppliers: changed };
            });
        }

        // Zoznam vedlajších jednotiek (balenie)
        if (type === ItemTypes.Unit) {

        }

        props.onClose();
    }

    return (
        <Dialog maxWidth="sm" fullWidth open={props.code !== undefined} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
            <Box>
                <DialogTitle>{props.code}</DialogTitle>
                <DialogContent>

                    <Typography>Použiť ako:</Typography>
                    <Paper sx={{ my: 2 }}>
                        <ButtonList activedKey={undefined} items={items ?? []} onClick={(item) => handleSelect(ItemTypes.Property, item.key)} />
                    </Paper>

                    {itemsSuppliers.length > 0 && (
                        <>
                            <Typography>Použiť ako kód dodávateľa:</Typography>
                            <Paper sx={{ my: 2 }}>
                                <ButtonList activedKey={undefined} items={itemsSuppliers ?? []} onClick={(item) => handleSelect(ItemTypes.Supplier, item.key)} />
                            </Paper>
                        </>
                    )}

                    {itemsUnits.length > 0 && (
                        <>
                            <Typography>Použiť ako kód vedľajšej jednotky (balenia)</Typography>
                            <Paper sx={{ my: 2 }}>
                                <ButtonList activedKey={undefined} items={itemsUnits ?? []} onClick={(item) => handleSelect(ItemTypes.Unit, item.key)} />
                            </Paper>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Späť</Button>
                </DialogActions>
            </Box>
        </Dialog >
    )
}

export default ProductCreateBarcode;