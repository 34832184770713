import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Number';
import { ConvertToDecimal } from '../../../utility/Number';

// Modely
import { Payment, PaymentTranslation, Language, PaymentType, Domain, PaymentSetting, PaymentTypeSetting, Modules, PriceListSettings } from '../../../models/Models';
import { File } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, Card, CardMedia, CardActions, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Typography, Table, TableHead, TableRow, TableCell, TableBody, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';
import CustomFieldValues from '../custom_field/CustomFieldValues';

// Ikony
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TuneIcon from '@mui/icons-material/Tune';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PercentIcon from '@mui/icons-material/Percent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Payment => ({
    id: 0,
    actived: true,
    type: 0,
    ordinalNumber: 0,
    fileId: 0,
    note: '',
    minimalPrice: 0,
    maximalPrice: 0,
    feeType: 0,
    feeValue: 0,
    feeFreeFrom: 0,
    feeVatRate: 0,
    settings: [],
    domains: [],
    translations: [],
    other: {
        fileSrcMedium: ''
    }
});

// Vstupné parametre
export interface PaymentCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const PaymentCreate = (props: PaymentCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Payment>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
    const [paymentTypeSettings, setPaymentTypeSettings] = useState<PaymentTypeSetting[]>([]);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [vatRates, setVatRates] = useState<number[]>([]);
    const [priceListSettings, setPriceListSettings] = useState<PriceListSettings>({ priceIncludeVat: false });

    // Načítam nastavenia
    const loadPriceListSettings = () => {
        axios
            .get(AppConfig.ApiUri + 'pricelist/settings')
            .then(response => setPriceListSettings(response.data));
    };
    useEffect(() => loadPriceListSettings(), []);

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'payment/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Payment);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => setLanguages(response.data.filter((language: Language) => language.actived)));
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam typov
    const loadPaymentTypes = () => {
        axios
            .get(AppConfig.ApiUri + 'paymenttype')
            .then(response => setPaymentTypes(response.data))
    };
    useEffect(() => loadPaymentTypes(), []);

    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => setDomains(response.data.filter((domain: Domain) => domain.actived)));
    };
    useEffect(() => loadDomains(), []);

    // Načítam zoznam sadzieb DPH
    const loadVatRates = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'vatrate/actual', { params: { 'date': new Date() } })
            .then(response => setVatRates(response.data));
    };
    useEffect(() => loadVatRates(), []);

    // Funkcia pre získanie zoznamu nastavení
    const loadSettings = useCallback(() => {
        if (source.type === 0) {
            setPaymentTypeSettings([]);
        }
        else {
            setPaymentTypeSettings(paymentTypes.find(item => item.id === source.type)?.settings ?? []);
        }
    }, [paymentTypes, source.type]);
    useEffect(() => loadSettings(), [loadSettings, source.type]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeSetting = (uid: string, property: string, value: any) => {
        setSource(prev => {
            const item: PaymentSetting = { ...prev?.settings?.find(item => item.uid === uid), uid: uid, [property]: value };
            const next = { ...prev, settings: [...prev?.settings?.filter(item => item.uid !== uid) ?? [], item] };
            return next;
        });
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: PaymentTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        // Odfiltrujem len nastavenia, ktoré sú platné pre daný typ (ak som zmenil typ, tak pole zostalo naplnené starými hodnotami)
        source.settings = source.settings?.filter(item => paymentTypeSettings.find(setting => setting.uid === item.uid) !== undefined) ?? [];

        // Skontrolujem nastavenia
        if ((paymentTypeSettings?.length ?? 0) > 0 && source.settings.length !== paymentTypeSettings.length) {
            setWarning({ ...EmptyWarning, text: 'Musíte vyplniť všetky natavenia!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'payment', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <FilesDialog {...filesDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Spôsob platby' : 'Nový spôsob platby')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }} alignItems="center">

                            <Grid item xs={12} mb={2}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>

                            <Grid item xs={12} sm={9}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="payment-create-type">Typ platby *</InputLabel>
                                    <Select required labelId="payment-create-type" label="Typ platby *" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        <MenuItem value={0}>Vlastná</MenuItem>
                                        {paymentTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <Grid key={language.id} item xs={12}>
                                        <TextField required margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                            inputRef={input => index === 0 && (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                            value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 70 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('limnit')} onChange={() => handleToggleAccordion('limnit')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Obmedzenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            <Grid item xs={12}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="payment-create-domain">Domény</InputLabel>
                                                    <Select multiple labelId="payment-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth margin="dense" name="minimalPrice" type="text" label="Minimálna suma objednávky" variant="outlined" autoComplete="off" value={source.minimalPrice ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'minimalPrice' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Hodnota objednávky musí byť minimálne v nastavenej sume, inak sa táto možnosť nezobrazí.">
                                                                    <ShoppingCartIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth margin="dense" name="maximalPrice" type="text" label="Maximálna suma objednávky" variant="outlined" autoComplete="off" value={source.maximalPrice ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'maximalPrice' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Hodnota objednávky musí byť maximálne v nastavenej sume, inak sa táto možnosť nezobrazí.">
                                                                    <ShoppingCartIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} mt={2}>
                                                <Alert severity="warning">
                                                    Obmedzenia ako minimálna suma objednávky a iné sú platné iba v rámci objednávkového systému pre zákazníka (eshop a podobne).
                                                    Obmedzenia nie sú uplatnené pri vytváraní objednávky cez administráciu (tu je možné použiť akýkoľvek záznam).
                                                </Alert>
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Alert severity="info">
                                                    Pri obmedzeniach (napr. domény) platí pravidlo, že ak je nie je označená žiadna možnosť, tak záznam platí pre všetko.
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {languages.map((language, index) => {
                                                return (
                                                    <Grid key={language.id} item xs={12}>
                                                        <TextField fullWidth multiline rows={5} margin="dense" name="note" label={'Poznámka (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                            value={source.translations?.find(item => item.languageId === language.id)?.note ?? ''}
                                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                            inputRef={input => index === 0 && props.autoFocus === 'note' && setFocus(input)}
                                                            inputProps={{ maxLength: 5000 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <EditIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                )
                                            })}

                                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                                            <Grid item xs={12}>
                                                <TextField fullWidth multiline rows={5} margin="dense" name="note" label="Interná poznámka" variant="outlined" value={source.note ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                    inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                    inputProps={{ maxLength: 5000 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('fee')} onChange={() => handleToggleAccordion('fee')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poplatok</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField required fullWidth margin="dense" name="feeValue" type="text" variant="outlined" autoComplete="off" value={source.feeValue ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    label={'Poplatok' + ((source.feeType ?? 0) === 0 ? ' ' + (priceListSettings.priceIncludeVat === true ? ' (s DPH)' : ' (bez DPH)') : '')}
                                                    inputRef={input => props.autoFocus === 'feeValue' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown, min: 0, max: (source.feeType === 1 ? 100 : 999999), step: 'any' }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Poplatok, ktorý bude účtovaný pri použitý tejto možnosti.">
                                                                    {source.feeType === 0 ? <EuroIcon className="formIconHelp" /> : <PercentIcon className="formIconHelp" />}
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={2}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="payment-create-feetype">Typ poplatku</InputLabel>
                                                    <Select required labelId="payment-create-feetype" label="Typ poplatku" value={source.feeType ?? 0} name="feeType" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'feeType' && setFocus(input)}>
                                                        <MenuItem value={0}>Fixná suma</MenuItem>
                                                        <MenuItem value={1}>Percentá</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={2}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="payment-create-feevatrate">DPH</InputLabel>
                                                    <Select required labelId="payment-create-feevatrate" label="DPH" value={source.feeVatRate ?? 0} name="feeVatRate" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                        inputRef={input => props.autoFocus === 'feeVatRate' && setFocus(input)}>
                                                        {vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <TextField fullWidth margin="dense" name="feeFreeFrom" type="text" label="Zdarma od sumy objednávky" variant="outlined" autoComplete="off" value={source.feeFreeFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'feeFreeFrom' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Ak hodnota objednávky dosiahne nastavenú sumu, tak je poplatok nie je účtovaný.">
                                                                    <EuroIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                {(paymentTypeSettings?.length ?? 0) > 0 &&
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => handleToggleAccordion('settings')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Nastavenia</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container columnSpacing={1}>
                                                <Grid item xs={12} mt={3}>
                                                    <Typography textAlign="center" variant="h6" gutterBottom>Nastavenia</Typography>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Parameter</TableCell>
                                                                <TableCell>Hodnota</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {paymentTypeSettings?.map((setting, index) => (
                                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell>
                                                                        <strong>{setting.uid}</strong> - {setting.name}
                                                                    </TableCell>
                                                                    <TableCell sx={{ width: '50%', p: 0.5, pl: 0 }}>
                                                                        <TextField required fullWidth size="small" name={setting.uid} variant="outlined" autoComplete="off"
                                                                            value={source.settings?.find(item => item.uid === setting.uid)?.value ?? ''}
                                                                            onChange={(e) => { handleChangeSetting(e.target.name, 'value', e.target.value) }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <TuneIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                }
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                                <Grid item xs={12} md={4}>
                                    {(source.fileId ?? 0) > 0 &&
                                        <Card sx={{ mt: 1 }}>
                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                            <CardActions>
                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                            </CardActions>
                                        </Card>
                                    }
                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                </Grid>
                            </Grid>

                            <CustomFieldValues moduleId={Modules.Payment} values={source.customFieldValues} languages={languages} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default PaymentCreate;