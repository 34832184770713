import { useEffect, useState } from 'react';
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AppRouteUrl } from '../../../AppRoutes';

// Modely
import { ImportQueue, ImportExportQueueStates, File } from '../../../models/Models';

// Utility
import { FindText } from '../../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../../utility/DataGrid';
import { useQuery } from '../../../utility/URL';
import format from 'date-fns/format';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridRowId, GridDensity } from "@mui/x-data-grid";
import { Button, Chip, Divider, Grid, IconButton, Link, ListItemIcon, Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Confirm, { ConfirmProps } from '../../../components/Confirm';
import Search from '../../../components/Search';
import ImportQueueInfo, { ImportQueueInfoProps } from './ImportQueueInfo';
import { Content, ContentTop, ContentBottom } from '../../../layout/Content';
import DataGridDensity from '../../../components/DataGridDensity';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';
import MessageBox, { MessageBoxProps } from '../../../components/MessageBox';

// Ikony
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import BlockIcon from '@mui/icons-material/Block';
import DescriptionIcon from '@mui/icons-material/Description';
import UploadFileIcon from '@mui/icons-material/UploadFile';

// Komponent pre zoznam 
const Countries = () => {

    // Počet sekúnd pre automatickú obnovu
    const timerTime = 30;

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [timer, setTimer] = useState<number>(timerTime);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [importQueueInfo, setImportQueueInfo] = useState<ImportQueueInfoProps>({
        open: false,
        keepMounted: true,
        onClose: () => setImportQueueInfo(prev => ({ ...prev, open: false }))
    });
    const [messageBox, setMessageBox] = useState<MessageBoxProps>({
        open: false, title: '', children: null, onClose: () => {
            setMessageBox(prev => ({
                ...prev, open: false
            }));
        }
    });

    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            handleFilesClose();
            if (files.length === 0 || files[0].fileIsImage || (files[0].fileExtension !== '.json' && files[0].fileExtension !== '.xml')) {
                setMessageBox(prev => ({
                    ...prev,
                    open: true,
                    title: 'Nesprávny formát: "' + files[0].fileExtension + '"',
                    children: <>Vybraný formát súboru nie je podporovaný.<br />Podporované formáty sú: .xml, .json.</>
                }));
                return;
            }
            let queue: ImportQueue = {
                note: 'Jednorázový import: ' + (files[0].name ?? '-'),
                state: 0,
                priority: 3,
                source: ((files[0].fileSrc ?? '')?.startsWith('/') ? window.location.protocol + '//' + window.location.host : '') + files[0].fileSrc
            };
            setLoading(true);
            axios
                .post(AppConfig.ApiUri + 'importqueue', queue)
                .then(response => {
                    if (response.data > 0) {
                        loadData();
                    }
                })
                .catch(() => { })
                .finally(() => {
                    setLoading(false);
                });
        },
        onClose: () => handleFilesClose()
    });

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'importqueues' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'source', headerName: 'Zdroj', hide: false, minWidth: 50, flex: 0.9,
            valueGetter: (params: GridValueGetterParams) => params.row.source,
            renderCell: (params: GridRenderCellParams<string>) => {
                let source = (params?.value ?? '');
                return <Link href={source} target='_blank' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{source}</Link>
            }
        },
        { field: 'note', headerName: 'Poznámka', hide: false, minWidth: 50, flex: 0.6 },
        { field: 'client', headerName: 'Klient', hide: false, minWidth: 50, flex: 0.4 },
        {
            field: 'state', headerName: 'Stav', hide: false, minWidth: 60, flex: 0.4,
            valueGetter: (params: GridValueGetterParams) => (ImportExportQueueStates.find(item => item.id === (params?.value ?? 0))?.name ?? ''),
            renderCell: (params: GridRenderCellParams<number>) => {
                let color = (ImportExportQueueStates.find(item => item.id === (params.row?.state ?? 0))?.color ?? '');
                let label = (params?.value ?? '');
                return <Chip label={label} size="small" variant="filled" sx={{ bgcolor: '#' + (color.length > 0 ? color : 'f0f0f0'), color: (color.length > 0 ? '#ffffff' : '#222222') }} />
            }
        },
        { field: 'priority', headerName: 'Priorita', hide: false, minWidth: 50, flex: 0.2, align: 'center', headerAlign: 'center' },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: false, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => format((params?.value as Date), 'dd.MM.yyyy HH:mm') ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 80, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton disabled={(params.row.state ?? 0) === 0} aria-label="Informácie" title="Informácie (enter)" size="small" onClick={() => handleInfo(params.value ?? 0)}>
                        <DescriptionIcon fontSize="small" />
                    </IconButton>
                    <IconButton disabled={(params.row.state ?? 0) >= 2} aria-label="Zrušiť" title="Zrušiť (delete)" size="small" onClick={() => handleCancel(params.value ?? 0, params.row.source)}>
                        <BlockIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Aplikujem uložené nastavenia
    useEffect(() => setColumns(columnsSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Tabuľka
    const [rows, setRows] = useState<ImportQueue[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<ImportQueue[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), []);

    // Pridať upraviť záznam
    const handleInfo = (id: number) => {
        setImportQueueInfo(prev => ({
            ...prev,
            id: id,
            open: true
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.IMPORT_QUEUE);
            handleInfo(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleCancel = (id: number, name: string) => {
        if (name.length > 25) {
            name = name.substring(0, 25) + '...';
        }
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete zrušiť tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'importqueue/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleCancelList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Zrušiť záznamy: ' + ids.length, children: 'Skutočne chcete zrušiť vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'importqueue/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [
                {
                    field: 'state',
                    getValue: (row: any) => {
                        return ImportExportQueueStates.find(item => item.id === (row?.state ?? 0))?.name ?? '';
                    }
                }
            ]
        });
    };

    // Funkcia pre načítanie dát z API
    const loadData = () => {
        setLoading(true);
        setTimer(timerTime);
        axios
            .get(AppConfig.ApiUri + 'importqueue')
            .then(response => {
                setRows(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => setRowsFiltered((search.length > 0 ? FindText(rows, search) : rows)), [rows, search]);

    // Časovač pre automatickú obnovu
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prev => (prev - 1));
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        if (timer < 0) {
            loadData();
        }
    }, [timer]);

    return (
        <>
            {/* Upozornenie */}
            <MessageBox {...messageBox} />

            {/* Výber súboru pre jednorázový import  */}
            <FilesDialog {...filesDialog} />

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ImportQueueInfo {...importQueueInfo} />

            {/* Obsah */}
            <Content>
                <ContentTop>

                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={12} md lg>

                            {/* Obnoviť zoznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large"
                                startIcon={
                                    loading === true ?
                                        <ReplayIcon sx={{ animation: "spin 2s linear infinite", "@keyframes spin": { "0%": { transform: "rotate(360deg)", }, "100%": { transform: "rotate(0deg)", }, }, }} /> :
                                        <ReplayIcon />
                                }
                                onClick={() => loadData()}>Obnoviť <Typography component="div" sx={{ width: '35px', textAlign: 'right', opacity: 0.4 }}>({timer})</Typography></Button>

                            {/* Vybrať súbor pre import */}
                            <Button color="primary" variant="outlined" sx={{ mr: 1 }} size="large" startIcon={<UploadFileIcon />} onClick={handleFilesOpen}>Importovať</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleCancelList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><BlockIcon fontSize="small" /></ListItemIcon> Zrušiť záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Zobrazenie */}
                        <Grid item xs md={2} textAlign="right">
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search onSearch={s => setSearch(s)}
                                onClear={() => {
                                    setSearch('');
                                    setFilterModel({ items: [], linkOperator: undefined });
                                }} />
                        </Grid>
                    </Grid>

                </ContentTop>
                <ContentBottom>

                    {/* Zoznam */}
                    <div style={{ display: 'flex', height: '100%' }}>
                        <DataGrid
                            getRowId={row => row.id}
                            density={density}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rowsFiltered}
                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            loading={loading}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                if ((e.row.state ?? 0) === 0) {
                                    return;
                                }
                                handleInfo(e.row.id);
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    if ((e.row.state ?? 0) === 0) {
                                        return;
                                    }
                                    handleInfo(e.row.id);
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    if ((e.row.state ?? 0) >= 2) {
                                        return;
                                    }
                                    handleCancel(e.row.id, e.row.source);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>

                </ContentBottom>
            </Content>
        </>
    )
}

export default Countries;